import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import { formLink } from '../CRUDComponent/Actions/ActionUtils';
import '../FormSubmit/FormSubmit.css';

const FormSubmit = (props) => {

  const additionalProps = {};

  if (props.className) {
    additionalProps.className = props.className;
  }

  return (
    <DefaultButton text={props.text}
      onClick={() => formLink(props.object, props)}
      {...props.buttonProps} {...additionalProps} />
  )

}

export default React.memo(FormSubmit);