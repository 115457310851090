import React from 'react';
import "./AppLoader.css";
import { Spinner } from 'office-ui-fabric-react';
import intl from 'react-intl-universal';

const AppLoader = (props) => {
    return (
        <div className="AppLoader">

            <div className="ImageContainer">
            </div>
            <Spinner label={props.label == null ? (intl.get("CleverApplication.loading")) : props.label} />
        </div>
    );
};

export default AppLoader;