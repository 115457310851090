import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import intl from "react-intl-universal";
import { Label, DetailsList, SelectionMode, Icon } from 'office-ui-fabric-react';
import { buildColumns } from 'office-ui-fabric-react/lib/DetailsList';
import { ScrollablePane } from 'office-ui-fabric-react';
import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    if (props.isFileDialogActive) {
        return '#2196f3';
    }
    return '#eeeeee';
}


class WagxFileDropZone extends Component {

    componentDidMount() {
      this.onChange([]);
    }

    onChange = (updatedFileArray) => {
      if(this.props.onChange != null) {
        this.props.onChange(null, this.props.field, updatedFileArray);
      }
    }

    removeItemHandler = (itemIndex, props) => {
        const updatedFilesArray = [...this.props.files];
        updatedFilesArray.splice(itemIndex, 1);

        this.setState({ files: updatedFilesArray });
        this.onChange(updatedFilesArray);
        this.props.onFileInputChanged(updatedFilesArray);
    }

    onDrop = (acceptedFiles) => {
        let updatedFiles = [...this.props.files];
        const indexesToRemove = [];

        for (let i = 0; i < acceptedFiles.length; i++) {
            const indexToRemove = updatedFiles.findIndex(updatedFile => updatedFile.name === acceptedFiles[i].name);
            if (indexToRemove > -1) {
                indexesToRemove.push(indexToRemove);
            }
        }

        indexesToRemove.sort().reverse().forEach(indexToRemove => updatedFiles.splice(indexToRemove, 1));
        updatedFiles = updatedFiles.concat(acceptedFiles); 
        this.onChange(updatedFiles);
        this.props.onFileInputChanged(updatedFiles);
        this.setState({ files: updatedFiles });
    }

    onRenderItemColumnHandler = (item, index, column) => {
        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case "thumbnail":
                return <Icon iconName={"DocumentApproval"} imageProps={{ height: "50", width: "50" }} />;
            case "delete":
                return <div style={{ height: "50", width: "50", cursor: "pointer" }}><Icon iconName="Cancel" onClick={() => this.removeItemHandler(index, this.props)} /></div>;
            default:
                return fieldContent;
        }
    }
    getColumns = (items) => {
        if (items.length === 0) {
            return [];
        }
        const columns = buildColumns(items);
        const thumbnailColumn = columns.filter(column => column.name === 'thumbnail')[0];
        thumbnailColumn.maxWidth = 20;
        return columns;
    }

    render() {
        const Container = styled.div`
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            border-color: ${props => getColor(props)};
            border-width: 2px;
            border-radius: 2px;
            border-style: dashed;
            background-color: #fafafa;
            color: #bdbdbd;
            outline: none;
            transition: border .24s ease-in-out;
            `;

        const files = this.props.files.map(file => { return { thumbnail: file.name, name: file.name, delete: file.name } });

        let fileListClassNames = ["ms-Grid-col"];
        if (this.props.fileListClassName != null) {
            fileListClassNames.push(this.props.fileListClassName);
        } else {
            fileListClassNames.push("ms-sm6");
        }

        let dropZoneClassNames = ["ms-Grid-col"];
        if (this.props.dropZoneClassName != null) {
            dropZoneClassNames.push(this.props.dropZoneClassName);
        } else {
            dropZoneClassNames.push("ms-sm6");
        }

        let fileNamesList = files.length > 0 ? <DetailsList items={files} columns={this.props.fileListColumns != null ? this.props.fileListColumns : this.getColumns(files)} selectionMode={SelectionMode.none} onRenderItemColumn={this.onRenderItemColumnHandler} isHeaderVisible={false} /> : null;
        if (this.props.scrollPaneProps != null) {
            fileNamesList = <div className={fileListClassNames.join(" ")} style={this.props.fileListStyle}><ScrollablePane  {...this.props.scrollPaneProps} >{fileNamesList}</ScrollablePane></div>;
        } else {
            fileNamesList = <div className={fileListClassNames.join(" ")} style={this.props.fileListStyle}>{fileNamesList}</div>;
        }


        let dropZoneContent = (
          <p>{intl.get("WAGX.FILE.DROP.ZONE.DROP_FILES").d("Trascina i file qui oppure clicca per caricare...")}</p>
        );

        if(this.props.children != null){
          dropZoneContent = this.props.children;
        }

        return (
            <Dropzone 
              onDrop={(acceptedFiles, fileRejections, event) => {this.onDrop(acceptedFiles)}} 
              noDragEventsBubbling={true} 
              accept={this.props.accept} 
              multiple={this.props.multiple} 
              disabled={this.props.disabled} 
              maxFiles={this.props.maxFiles}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused, isFileDialogActive }) => (
                    <section className="WagxFileDropZone container">
                        <div className={dropZoneClassNames.join(" ")}>
                            <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject, isFocused, isFileDialogActive })}>
                                <input {...getInputProps()} />
                                {dropZoneContent}
                            </Container>
                            {!this.props.valid ? <Label style={{ "color": "rgb(168, 0, 0)" }} >{this.props.errorMessage}</Label> : null}
                            {fileNamesList}
                        </div>
                    </section>
                )}
            </Dropzone>
        )
    }
}

export default WagxFileDropZone;