import { createTheme } from "@material-ui/core";

const dev_config = {
  baseURL: window.location.protocol + '//localhost:8090/cicostool',
  BASE_PATH_NAME: "/cicostool/App"
}
const prod_config = {
  baseURL: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/cicostool",
  BASE_PATH_NAME: "/cicostool/App"
}

export const BASE_APP_NAME = "/cicostool";
export const BASE_PATH_NAME = "/cicostool/App";

export const DEFAULT_MATERIAL_THEME = createTheme({
  palette: {
    primary: {
      main: 'rgb(29, 92, 147)'
    }
  }
});

window.APP_CONFIG = process.env.NODE_ENV === "production" ? prod_config : dev_config;
export default process.env.NODE_ENV === "production" ? prod_config : dev_config;
window.wagxBroadcastChannel = new BroadcastChannel("cicostoolBroadcastChannel");