export const INSERT = "Insert";
export const EXPORT = "Export";
export const LINK = "Link";
export const REFRESH = "Refresh";
export const TAB_REFRESH = "TabRefresh";
export const USER_LOG = "UserLog";
export const CUSTOM_LOAD_DATA = "Custom Load Data";
export const FILE_DOWNLOAD = "FILE_DOWNLOAD";
export const CUSTOM_DATA_SEND = "Custom Data Send";
export const CUSTOM_DATA_SEND_EXT = "Custom Data Send Ext";
export const EXECUTE_FUNCTION = "Execute Function";
export const OPEN_VIEW = "OPEN_VIEW";
export const OPEN_IN_MODAL = "OPEN_IN_MODAL";
export const PUSH_TO_PAGE_STATE = "PUSH_TO_PAGE_STATE";
export const RESET_PAGE_STATE = "RESET_PAGE_STATE";
export const BACK_TO_HOME_OPERATIVA = "BACK_TO_HOME_OPERATIVA";
export const PAGE_REFRESH = "PAGE_REFRESH";
export const FORM_LINK = "FORM_LINK";
export const ACTION_BUTTON = "actionButton";
export const ENGINE_WORKFLOW = "engineWorkflow";
export const SUB_MENU_ACTIONS = "subMenuActions";
export const CLOSE_MODAL = "closeModal";
export const PROPS_FUNCTION = "PROPS_FUNCTION";