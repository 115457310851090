import 'date-fns';
import React, { useState, useEffect } from 'react';
import moment from 'moment-with-locales-es6';
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    DateTimePicker as MUIDateTimePicker
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { DEFAULT_MATERIAL_THEME } from '../../../config';

const DateTimePicker = (props) => {
    const { onChange, field } = props
    const [selectedDate, setSelectedDate] = useState(props.emptyDefault ? null : new Date());

    useEffect(() => { onChange(undefined, field, selectedDate != null ? (selectedDate.toDate != null ? selectedDate.toDate().getTime() : selectedDate.getTime()) : null) }, [selectedDate, field, onChange]);

    function handleDateChange(date) {
        setSelectedDate(date);
    }

    const parseMinDate = () => {
        if (props.minDate) {
            switch (props.minDate) {
                case "TODAY":
                    return new Date();
                default:
                    break;
            }
        }
        return undefined;
    }

    const className = ["DatePickerContainer"];
    let formFeedBack = null;

    if (props.className != null) {
        className.push(props.className);
    }
    if (props.valid === false || (props.formFeedBack && props.formFeedBack !== "")) {
        className.push("invalidBox");
        formFeedBack = <p style={{ color: "rgb(168, 0, 0)" }}>{props.formFeedBack}</p>;
    }

    return (
        <ThemeProvider theme={DEFAULT_MATERIAL_THEME}>
            <div className={className.join(" ")}>
                <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} >
                    <MUIDateTimePicker
                        {...props}
                        minDate={parseMinDate()}
                        ampm={false}
                        format={props.format ? props.format : "DD/MM/YYYY HH:mm"}
                        value={selectedDate}
                        onChange={handleDateChange}
                        variant="inline"
                        PopoverProps={{ style: { zIndex: "1000000" } }}
                    />
                </MuiPickersUtilsProvider>
                {formFeedBack}
            </div>
        </ThemeProvider>
    );
};

export default DateTimePicker;