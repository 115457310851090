import React, { Component } from "react";
import './WagxLink.css';
import { NavLink } from "react-router-dom";
import { Icon } from "office-ui-fabric-react";
import { parseString } from "../../functions/utility";
import * as formatters from "../../functions/formatters";
import ViewContext from "../../containers/ViewContext/ViewContext";
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import { WagxWhiteSpace, calculatePreAndPostSpaces } from '../WagxWhiteSpace/WagxWhiteSpace';
import { getLocalizedProperty } from "../../LocaleUtils";

/**
 * @class WagxWebNav
 * @description React Stateless Component for Navigation between CURDViews
 * @param destinationTitle
 * @param object
 * @param filters
 * @param path
 * @author selectsrl
 */
class WagxLink extends Component {
  render() {
    const props = this.props;
    const destinationTitle = props.destinationTitle ? getLocalizedProperty(props, "destinationTitle", null, props.object) : null;
    const className = props.className ? parseString(props.className, props.object) : "";
    const filters = props.filters ?
      props.filters.map(filter => {
        const newFilter = {
          ...filter
        };
        if (filter.notEq === true) {
          if (filter.field) {
            // eslint-disable-next-line 
            newFilter.value = "" + eval("props.object." + filter.field);
          } else if (filter.value !== undefined) {
            newFilter.value = "" + filter.value;
          }
        } else if (typeof filter.value !== "string" || !filter.value.startsWith("EQ(")) {
          if (filter.field) {
            // eslint-disable-next-line 
            newFilter.value = "EQ(" + eval("props.object." + filter.field) + ")";
          } else if (filter.value !== undefined) {
            newFilter.value = "EQ(" + filter.value + ")";
          }
        }
        return newFilter;
      }) :
      null;

    let textItemArray = [];
    if (Array.isArray(props.text)) {
      for (let index = 0; index < props.text.length; index++) {
        let textItem = props.text[index];
        let text = getLocalizedProperty(textItem, "text", null, props.object);
        if ((text === "" || text === null) && textItem.placeholder !== "" && textItem.placeholder !== null) {
          text = props.placeholder
        }
        if ((text === "" || text === null) && props.placeholder !== "" && props.placeholder !== null) {
          text = props.placeholder
        }
        if (textItem.keepLeadingAndTrailingSpaces) {
          const [initialSpace, finalSpace] = calculatePreAndPostSpaces(textItem.text); // The calcuation is done on template string.
          textItemArray.push(<WagxWhiteSpace key={index + "_pre"} numberOfSpaces={initialSpace} />);
          if (props.formatter != null) {
            text = props.formatterParameters ? formatters[props.formatter](text, props.formatterParameters) : formatters[props.formatter](text);
          }
          textItemArray.push(<span key={index} {...textItem.spanProps}>{text}</span>);
          textItemArray.push(<WagxWhiteSpace key={index + "_post"} numberOfSpaces={finalSpace} />);
        } else {
          textItemArray.push(<span key={index} {...textItem.spanProps}>{text}</span>);
        }
      }
    } else {
      let text = getLocalizedProperty(props.columnStructure != null ? props.columnStructure : props , "text", null, props.object);
      if ((text === "" || text === null) && props.placeholder !== "" && props.placeholder !== null) {
        text = props.placeholder
      }
      if (props.formatter != null) {
        text = props.formatterParameters ? formatters[props.formatter](text, props.formatterParameters) : formatters[props.formatter](text);
      }
      textItemArray.push(<span key="0">{text}</span>);
    }

    const path = parseString(props.path, props.object, null, props.loadedObject);
    let state = props.mantainApplyFilters === true ? Object.assign({}, this.context != null && this.context.location != null ? this.context.location.state : null) : null;
    let fromLink = true;
    if (props.fromLink != null) {
      fromLink = props.fromLink;
    }
    if (props.mantainApplyFilters == null || props.mantainApplyFilters === false) {
      if (props.viewStateAttribute && this.context != null && this.context.location != null) {
        state = Object.assign({}, this.context.location.state);
        if (Array.isArray(props.viewStateAttribute)) {
          props.viewStateAttribute.forEach(viewStateAttr => {
            state[viewStateAttr] = {
              filters: [],
              applyFilters: filters,
              destinationTitle: destinationTitle,
              page: 0,
              fromLink: fromLink
            };
          });
        } else {
          state[props.viewStateAttribute] = {
            filters: [],
            applyFilters: filters,
            destinationTitle: destinationTitle,
            page: 0,
            fromLink: fromLink
          };
        }
      } else {
        state = {
          filters: [],
          applyFilters: filters,
          destinationTitle: destinationTitle,
          page: 0,
          fromLink: fromLink
        };
      }
    }
    const to = {
      pathname: path,
      state: state
    };
    let showLink = true;
    if (!props.insideGenericForm && props.showIf) {
      const parsedString = parseString(props.showIf, props.object);
      try {
        // eslint-disable-next-line 
        showLink = new Function('object', 'return ' + parsedString)(props.object);
      } catch (e) {
        console.error(parsedString, e);
      }
    }
    let showIcon = true;
    if (props.showIcon != null) {
      showIcon = props.showIcon;
    }

    let link = null;


    if (showLink) {
      if (props.external) {
        link = (
          <button
            title={getLocalizedProperty(props, "title", props.title, props.object)}
            className={"wagx-link wagx-link-external " + (className ? className : "")}
            type={props.buttonType != null ? props.buttonType : "button"}
            onClick={() => {
              window.open(path, "_blank")
              if (props.closeModalOnClick === true) {
                props.hideSearchModal();
              }
              if (this.props.onAfterClick) {
                this.props.onAfterClick(path, props);
              }
            }}
            disabled={props.disabled}
          >
            {textItemArray}
            {props.icon ? < Icon iconName={props.icon} className={className} /> : null}
          </button>
        );
      } else if (props.openInModal) {
        if (props.openViewFromLink) {
          if (props.actionProps != null) {
            link = (
              <button
                className={"wagx-link wagx-link-openInModal-openViewFromLink " + (className ? className : "")}
                tabIndex="0"
                type={props.buttonType != null ? props.buttonType : "button"}
                onClick={() => {
                  props.openViewHandler(props, props.actionProps)
                  if (this.props.onAfterClick) {
                    this.props.onAfterClick(null, props);
                  }
                }}
                disabled={props.disabled}
              >
                {textItemArray}
                {showIcon ? <Icon iconName={props.icon ? props.icon : "OpenInNewWindow"} className={className !== "" ? className : null} /> : null}
              </button>
            );
          }
        } else {
          const title = props.modalTitle ? props.modalTitle : null;
          const width = props.modalWidth ? props.modalWidth : null;
          const height = props.modalHeight ? props.modalHeight : null;
          const updatePageOnClose = props.updatePageOnClose ? props.updatePageOnClose : null;
          link = (
            <button
              className={"wagx-link wagx-link-openInModal " + (className ? className : "")}
              tabIndex="0"
              type={props.buttonType != null ? props.buttonType : "button"}
              onClick={() => {
                this.props.showIframeModal(path, title, width, height, updatePageOnClose)
                if (this.props.onAfterClick) {
                  this.props.onAfterClick(path, props);
                }
              }}
              disabled={props.disabled}
            >
              {textItemArray}
              {showIcon ? <Icon iconName={props.icon ? props.icon : "OpenInNewWindow"} className={className !== "" ? className : null} /> : null}
            </button>
          );

        }

      } else if (path.startsWith("/")) {
        link = (
          <NavLink
            className={"wagx-link wagx-link-navlink"}
            onClick={() => {
              if (props.closeModalOnClick && props.showSearchModal) {
                props.hideSearchModal()
              }
              if (this.props.onAfterClick) {
                this.props.onAfterClick(null, props);
              }
            }}
            to={to}
            disabled={props.disabled}
          >
            {textItemArray}
            {props.icon ? <Icon iconName={props.icon} className={className} /> : null}
          </NavLink>
        );
      } else {
        const title = getLocalizedProperty(props, "title", props.title, props.object);

        link = (
          <button
            title={title}
            className={"wagx-link wagx-link-others " + (className ? className : "")}
            type={props.buttonType != null ? props.buttonType : "button"}
            onClick={() => {
              window.open(path, "_blank")
              if (this.props.onAfterClick) {
                this.props.onAfterClick(path, props);
              }
            }}
            disabled={props.disabled}
          >
            {textItemArray}
            {showIcon ? <Icon iconName={props.icon ? props.icon : "OpenInNewWindow"} className={className} /> : null}
          </button>
        );
      }
    } else {
      link = (
        <span>
          {textItemArray}
        </span>
      );
    }
    return link;
  }
}

WagxLink.contextType = ViewContext;

const mapDispatchToProps = dispatch => {
  return {
    hideSearchModal: () => dispatch(actions.hideSearchModal()),
    showIframeModal: (url, title, width, height, updatePageOnClose) => dispatch(actions.showIframeModal({ url: url, title: title, width: width, height: height, updatePageOnClose: updatePageOnClose })),
    showSearchModal: (search, mainStyle, objectId, loadedObject) => dispatch(actions.showSearchModal({ search: search, mainStyle: mainStyle, objectId: objectId, loadedObject: loadedObject })),
  };
}
const mapStateToProps = state => {
  return {
    showSearchModal: state.search.showModal
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WagxLink);