import React, { PureComponent } from 'react';
import axios from '../../axios-wagx-web';
import { IconButton } from 'office-ui-fabric-react';
import Spinner from '../../UI/Spinner/Spinner';
import './IconSwitchComponent.css';
import * as evaluateFunctions from '../../functions/utility';

class IconSwitchComponent extends PureComponent {
  constructor(props) {
    super(props);
    //Inizializzazione STATE
    // eslint-disable-next-line eqeqeq
    const iconName = this.props.object[this.props.field] == 1 ? this.props.favouriteIcon : this.props.notFavouriteIcon;

    this.state = {
      iconName: iconName,
      changing: false,
      className: evaluateFunctions.parseString(props.className, this.props.object)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.object[this.props.field] !== prevProps.object[this.props.field]) {
      // eslint-disable-next-line eqeqeq
      const iconName = this.props.object[this.props.field] == 1 ? this.props.favouriteIcon : this.props.notFavouriteIcon;
      this.setState({ iconName: iconName })
    }
  }


  onIconSwitchChange = (props) => {
    this.setState({ changing: true }, () => {
      const changeIconSwitchUrl = "/views/" + props.viewId + "/" + props.functionName;
      axios.post(changeIconSwitchUrl, props.object).then((response) => {
        if (response.data.success === true) {
          props.object[props.field] = response.data.value[props.field];
          if (this.props.reloadData) {
            props.loadData();
          }
          this.setState({
            // eslint-disable-next-line eqeqeq
            iconName: response.data.value[props.field] == 1 ? props.favouriteIcon : props.notFavouriteIcon,
            changing: false,
            className: evaluateFunctions.parseString(props.className, response.data.value)
          });
        } else {
          evaluateFunctions.showMessageModal(response.data.success, null, response.data.responseMessages, true);
        }
      });
    });
  }

  render() {
    return (
      <div className="IconSwitchComponent">
        {
          this.state.changing ?
            <Spinner /> :
            <IconButton
              disabled={this.props.disabled}
              onClick={() => this.onIconSwitchChange(this.props)}
              iconProps={{ iconName: this.state.iconName }}
              className={this.state.className}
            />
        }
      </div>
    );
  }
}

export default IconSwitchComponent;