export const ItemTypes = {
    BOX: 'box',
}
export const ClvConditionType = {
    COMPLEX: "complex",
    SIMPLE: "simple",
    EMPTY: "empty"
}
export const ClvSimpleConditionType = {
    EQ: "EQ", NEQ: "NEQ", SW: "SW"
}
export const ClvLogicalOperatorType = {
    AND: "AND", OR: "OR"
}
export const ClvDimensioneInputType = {
    TEXT: "Text", SELECT: "Select"
}