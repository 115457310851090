import React from "react";
import { ComboBox, Stack } from 'office-ui-fabric-react';
import './ComboBoxWithRenderLabel.css'

const ComboBoxWithRenderLabel = (props) => {
  const isError = (props.errorMessage != null && props.errorMessage != null) || props.invalid;
  if (props.onRenderLabel) {
    const className = ["combo-box-with-render-label"];
    let additionalProps = {};

    if (isError) {
      className.push("invalid");
    }
    if (props.disabled !== true && props.readOnly === true) {
      additionalProps.disabled = true;
      className.push("read-only")
    }

    return (
      <Stack className={className.join(" ")}  >
        <Stack.Item align="auto" className="title">
          {props.onRenderLabel(props, null)}
        </Stack.Item>
        <Stack.Item align="auto" className="content">
          <ComboBox
            {...props}
            label={null}
            errorMessage={null}
            {...additionalProps}
          />
        </Stack.Item>
      </Stack>
    );
  } else {
    return (
      <ComboBox
        {...props}
      />
    );
  }
}
export default ComboBoxWithRenderLabel;
