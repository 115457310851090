import { Icon, Stack } from 'office-ui-fabric-react';
import React from 'react';

export const ErrorLabel = (props) => {
  const label = props.label;
  const stackTokens = { childrenGap: 4, maxWidth: 300 };
  const errorIconProps = props.iconProps != null ? props.iconProps : { iconName: 'Error', style: { color: "rgb(168,0,0)", fontSize: "14px", cursor:"pointer" } };
  const warnigIconProps = props.iconProps != null ? props.iconProps : { iconName: 'Warning', style: { color: "rgb(255, 102, 0)", fontSize: "14px", paddingRight: "5px", cursor:"pointer" } };
  return (
    label != null || (props.errorMessage != null && props.errorMessage !== "") || (props.warningMessage != null && props.warningMessage !== "") ?
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={stackTokens}>
        {label}
        <span>
          {(props.errorMessage == null || props.errorMessage === "") && props.warningMessage != null && props.warningMessage !== "" ?
            <Icon
              {...warnigIconProps}
              title={props.warningMessage}
              ariaLabel={props.warningMessage}
            />
            :
            null
          } 
          {props.errorMessage != null && props.errorMessage !== "" ?
            <Icon
              {...errorIconProps}
              title={props.errorMessage}
              ariaLabel={props.errorMessage}
            />
            : null}
        </span>
      </Stack>
      : null
  );
}