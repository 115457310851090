import { ActionButton } from 'office-ui-fabric-react';
import React, { PureComponent } from 'react';
import intl from 'react-intl-universal';
import { computeFunctionBody, getValueFromInput, isNotNullOrEmpty } from '../../../functions/utility';
import { getLocalizedProperty } from '../../../LocaleUtils';
import GenericInput from '../../GenericInput/GenericInput';
import * as DataTableTypes from '../DataTableTypes';
import './DataTableToolbar.css';


class DataTableToolbar extends PureComponent {

  constructor(props) {
    super(props);
    this.searchMode = this.props.searchMode ? this.props.searchMode : DataTableTypes.FILTER
    this.state = {
      filters: [],
      isOpen: true
    };
    for (const key in props.filterComponents) {
      if (props.filterComponents.hasOwnProperty(key)) {
        const field = props.filterComponents[key].field;
        this.state.filters[field] = { type: props.filterComponents[key].type, value: "" };
        if (props.filters && props.filters[field]) {
          this.state.filters[field].value = props.filters[field].value;
        }
      }
    }
  }

  filterValueChangeHandler = (event, customInputName, selectedKey) => {
    const oInputvalue = getValueFromInput(event, customInputName, selectedKey);
    const updatedFilters = Object.assign({}, this.state.filters);
    updatedFilters[customInputName].value = oInputvalue;
    this.setState({ filters: updatedFilters });


    if (this.props.afterOnChange) {
      this.props.afterOnChange();
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    const submittedFilters = Object.assign({}, this.state.filters);
    this.props.onFilterList(submittedFilters);
  }

  toggleCollapseHandler = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filters !== this.props.filters) {
      let filters = [];
      for (const key in this.props.filterComponents) {
        if (this.props.filterComponents.hasOwnProperty(key)) {
          const field = this.props.filterComponents[key].field;
          filters[field] = { type: this.props.filterComponents[key].type, value: "" };
          if (this.props.filters && this.props.filters[field]) {
            filters[field].value = this.props.filters[field].value;
          }
        }
      }
      this.setState({ filters: filters });
    }
  }

  render() {
    const rows = [];
    let index = 0;
    let size = 0;

    let currentRow = [];
    this.props.filterComponents.forEach(filterComponent => {
      if (filterComponent.showIf != null) {
        const funcBody = computeFunctionBody(filterComponent.showIf);
        // eslint-disable-next-line 
        const func = new Function("object", "profiles", funcBody);
        const result = func(this.props.object == null ? {} : this.props.object, this.props.profiles);

        if (result === false) {
          return;
        }
      }

      const value = this.state.filters[filterComponent.field].value;
      const xs = { size: filterComponent.xs && filterComponent.xs.size ? filterComponent.xs.size : "2", offset: filterComponent.xs && filterComponent.xs.offset ? filterComponent.xs.offset : "0" };
      if (size + (+xs.size) > 12) {
        // nuova riga!
        rows.push(currentRow);
        currentRow = [];
        size = 0;
      }
      size += (+xs.size);
      const filterBoxClassName = ["filterBox"];

      if (isNotNullOrEmpty(filterComponent.className)) {
        filterBoxClassName.push(filterComponent.className);
      }

      currentRow.push(
        <GenericInput
          {...filterComponent}
          key={index}
          value={value}
          type={filterComponent.type}
          valid={true}
          formatter={filterComponent.formatter}
          restUrl={filterComponent.restUrl}
          field={filterComponent.field}
          placeholder={getLocalizedProperty(filterComponent, 'placeholder')}
          lovId={filterComponent.lovId}
          onChange={this.filterValueChangeHandler}
          applyFilters={this.props.applyFilters}
          lovValue={filterComponent.lovValue}
          remapAppliedFiltersFields={filterComponent.remapAppliedFiltersFields}
          className={filterBoxClassName.join(" ")}
          xs={xs}
        />
      );
      index++;
    });
    if (currentRow.length > 0) {
      rows.push(currentRow);
    }
    const filters = rows.map((row, index) => {
      return (
        <div key={index} className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            {row}
          </div>
        </div>
      )
    });

    //Eseguo operazioni differenti in base al tipo di ricerca impostato
    let actionButton;
    switch (this.searchMode) {
      case DataTableTypes.FILTER:
        actionButton = <ActionButton className="DataTableToolbarButton" type="submit" primary iconProps={{ iconName: "Filter", iconPosition: "right" }}>{intl.get("DataTableToolbar.filterButton").d("Filtra")}</ActionButton>;
        break;
      case DataTableTypes.SEARCH:
        actionButton = <ActionButton className="DataTableToolbarButton" type="submit" primary iconProps={{ iconName: "Search", iconPosition: "right" }}>{intl.get("DataTableToolbar.searchButton").d("Cerca")}</ActionButton>;
        break;
      default:
        actionButton = <ActionButton className="DataTableToolbarButton" type="submit" primary iconProps={{ iconName: "Filter", iconPosition: "right" }}>{intl.get("DataTableToolbar.filterButton").d("Filtra")}</ActionButton>;
        break;
    }

    const dataTableToolbarClasses = ["DataTableToolbar"];
    if (this.props.className != null) {
      dataTableToolbarClasses.push(this.props.className);
    }

    return (
      <div className={dataTableToolbarClasses.join(" ")}>
        <form onSubmit={this.submitHandler}>
          <div className="ms-Grid DataTableToolbarOutherGrid" dir="ltr">
            <div className="ms-Grid-row ms-bgColor-neutralLighter filterBar">
              <div className="ms-Grid-col ms-sm11">
                {filters}
              </div>
              <div className="ms-Grid-col ms-sm1 ms-textAlign-right">
                {actionButton}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DataTableToolbar;
