import React, { Component } from 'react';
import { TooltipHost } from 'office-ui-fabric-react';
import DatePicker from './DatePicker';
import { getLocalizedProperty } from '../../LocaleUtils';

export default class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRangeValue: new DateRangeValue()
    }
  }

  onChangeFrom = (event, field, value, errors, forcedFieldValidation) => {
    let dateRangeValue = new DateRangeValue(this.state.dateRangeValue.toString());
    dateRangeValue.fromDate = value != null ? value.valueOf() : null;
    this.setState({ dateRangeValue: dateRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.dateRangeValue.toString(), null, this.props.formId);
    });

  }

  onChangeTo = (event, field, value, errors, forcedFieldValidation) => {
    let dateRangeValue = new DateRangeValue(this.state.dateRangeValue.toString());
    dateRangeValue.toDate = value != null ? value.valueOf() : null;
    this.setState({ dateRangeValue: dateRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.dateRangeValue.toString(), null, this.props.formId);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value && this.state.dateRangeValue.toString() !== this.props.value) {
      let dateRangeValue = new DateRangeValue(this.props.value);
      this.setState({ dateRangeValue: dateRangeValue });
    }
  }
  render() {
    let fromDatePicker, toDatePicker = null;
    const dateRangeValue = new DateRangeValue(this.props.value);
    if (this.props.fromDateProps) {
      const fromDateProps = this.props.fromDateProps;
      const placeholderFrom = getLocalizedProperty(fromDateProps, "placeholder");
      fromDatePicker = (
        <TooltipHost content={placeholderFrom} id={this.hostId}>
          <DatePicker
            disabled={this.props.disabled}
            value={dateRangeValue.fromDate === null ? null : new Date(dateRangeValue.fromDate)}
            allowTextInput={true}
            onChange={this.onChangeFrom}
            onRenderLabel={this.props.customLabelRender}
            field={this.props.field}
            maxDate={dateRangeValue.toDate ? new Date(dateRangeValue.toDate) : null}
            formId={this.props.formId}
            {...fromDateProps}
            placeholder={placeholderFrom}
          />
        </TooltipHost>
      );

    }

    if (this.props.toDateProps) {
      const toDateProps = this.props.toDateProps;
      const placeholderTo = getLocalizedProperty(toDateProps, "placeholder");
      toDatePicker = (
        <TooltipHost content={placeholderTo} id={this.hostId}>
          <DatePicker
            disabled={this.props.disabled}
            value={dateRangeValue.toDate === null ? null : new Date(dateRangeValue.toDate)}
            allowTextInput={true}
            onChange={this.onChangeTo}
            onRenderLabel={this.props.customLabelRender}
            minDate={dateRangeValue.fromDate ? new Date(dateRangeValue.fromDate) : null}
            field={this.props.field}
            {...toDateProps}
            placeholder={placeholderTo}
          />
        </TooltipHost>
      );
    }

    return (
      this.props.inline === true
        ? (
          <div className={"ms-Grid" + this.props.className}>
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6">
                {fromDatePicker}
              </div>
              <div className="ms-Grid-col ms-sm6">
                {toDatePicker}
              </div>
            </div>
          </div>
        )
        : (
          <React.Fragment>
            {fromDatePicker}
            {toDatePicker}
          </React.Fragment>
        )
    );
  }
}

export class DateRangeValue {
  constructor(value) {
    this.toDate = null;
    this.fromDate = null;
    this.operator = null;
    if (value != null && typeof value === "string") {

      if (value.startsWith("BW(") && value.endsWith(")")) {
        value = value.substring(3, value.length - 1);
        value = value.split(";");
        this.fromDate = parseInt(value[0], 10);
        this.toDate = parseInt(value[1], 10);
        this.operator = "BW";
      } else if (value.startsWith("GTE(") && value.endsWith(")")) {
        value = value.substring(4, value.length - 1);
        this.operator = "GTE";
        value = value.split(";");
        this.fromDate = parseInt(value[0], 10);
        this.toDate = null;
      } else if (value.startsWith("LTE(") && value.endsWith(")")) {
        value = value.substring(4, value.length - 1)
        this.operator = "LTE";
        value = value.split(";");
        this.fromDate = null;
        this.toDate = parseInt(value[0], 10);
      }
    }
  }

  toString = () => {
    let value = null;
    if (this.toDate != null && this.fromDate != null) {
      value = "BW(" + this.fromDate + ";" + this.toDate + ")";
    } else if (this.toDate == null && this.fromDate != null) {
      value = "GTE(" + this.fromDate + ")";
    } else if (this.fromDate == null && this.toDate != null) {
      value = "LTE(" + this.toDate + ")";
    }
    return value;
  }
}