import loadash from 'lodash';
import { parseStringFromApplyFilters } from '../../functions/utility';

export const setApplyFilters = (state, pageAttribute, viewStateAttribute, mandatoryFilter, objectId) => {
    let addedApplyFilters = [{
        param: mandatoryFilter.param,
        value: objectId
    }];
    if (pageAttribute.applyFilters != null && !mandatoryFilter.fromRoute) {
        const currentFilterIndex = pageAttribute.applyFilters.findIndex(applyFilter => applyFilter.param === mandatoryFilter.param);
        if (currentFilterIndex > -1) {
            pageAttribute.applyFilters[currentFilterIndex].value = objectId;
            addedApplyFilters = [];
        }
    }
    pageAttribute.applyFilters = pageAttribute.applyFilters != null ? [...addedApplyFilters, ...pageAttribute.applyFilters] : addedApplyFilters;

    state[viewStateAttribute] = pageAttribute;
}
export const pushToPageState = (props, routerState, action, object, componentApplyFilters = null) => {
    const pageState = Object.assign({}, props.page);
    let applyFiltersToPage = [];
    action.pushStates.forEach(pushState => {
        if (pageState[pushState.pageViewStateAttribute] == null) {
            pageState[pushState.pageViewStateAttribute] = {};
        }
        if (pushState.viewStateAttribute == null) {
            pageState[pushState.pageViewStateAttribute] = { ...pushState.state };
        } else {
            pageState[pushState.pageViewStateAttribute][pushState.viewStateAttribute] = { ...pushState.state };
            let objectIdField = null;
            if (pushState.objectIdField != null) {
                objectIdField = loadash.get(object, pushState.objectIdField, null);
                if (objectIdField != null) {
                    pageState[pushState.pageViewStateAttribute][pushState.viewStateAttribute].objectId = objectIdField;
                }
            }
            if (pushState.state.mandatoryFilters != null) {
                applyFiltersToPage.push({
                    pushState: pushState.state,
                    pageViewStateAttribute: pushState.pageViewStateAttribute,
                    viewStateAttribute: pushState.viewStateAttribute,
                    objectId: objectIdField
                });
            }
        }
    });
    if (applyFiltersToPage.length > 0) {
        const routerViewState = Object.assign({}, routerState);
        applyFiltersToPage.forEach(applyFilterToPage => {
            const pageAttribute = Object.assign({}, routerViewState[applyFilterToPage.viewStateAttribute]);
            applyFilterToPage.pushState.mandatoryFilters.forEach(mandatoryFilter => {
                pageAttribute.applyFilters = pageAttribute.applyFilters != null ? pageAttribute.applyFilters.filter(applyFilter => applyFilter.param !== mandatoryFilter.param) : [];
            });
            applyFilterToPage.pushState.mandatoryFilters.forEach(mandatoryFilter => {
                let filterValue = null;
                if (mandatoryFilter.value && componentApplyFilters) {
                    filterValue = parseStringFromApplyFilters(mandatoryFilter.value, componentApplyFilters);
                } else {
                    if (mandatoryFilter.fromParent && componentApplyFilters) {
                        filterValue = parseStringFromApplyFilters("{" + mandatoryFilter.param + "}", componentApplyFilters);
                    } else {
                        filterValue = applyFilterToPage.objectId != null && !mandatoryFilter.fromRoute ? applyFilterToPage.objectId : props.match.params.objectId;
                    }
                }

                if (mandatoryFilter.applyEQ) {
                    filterValue = "EQ(" + filterValue + ")";
                }
                setApplyFilters(routerViewState,
                    pageAttribute,
                    applyFilterToPage.viewStateAttribute,
                    mandatoryFilter,
                    filterValue);
            });
            if (applyFilterToPage.pushState.filterOnly) {
                pageState[applyFilterToPage.pageViewStateAttribute][applyFilterToPage.viewStateAttribute] = null;
            }
        });
        props.history.push(props.location.pathname, routerViewState);
    }
    props.setPageState(pageState);
}
export const resetPageState = (props, routerState, action) => {
    const pageState = Object.assign({}, props.page);
    action.pageStatesToReset.forEach(pageStateToReset => {
        if (pageState[pageStateToReset.pageViewStateAttribute] == null) {
            pageState[pageStateToReset.pageViewStateAttribute] = {};
        }
        if (pageStateToReset.pageViewRootClass != null) {
            pageState[pageStateToReset.pageViewStateAttribute].pageViewRootClass = pageStateToReset.pageViewRootClass;
        }
        if (pageStateToReset.viewStateAttribute) {
            let viewStateToAssign = {}
            if (pageStateToReset.viewState) {
                Object.assign(viewStateToAssign, pageStateToReset.viewState);
            } else {
                Object.assign(viewStateToAssign, { loadData: true });
            }
            pageState[pageStateToReset.pageViewStateAttribute][pageStateToReset.viewStateAttribute] = {};
            Object.assign(pageState[pageStateToReset.pageViewStateAttribute][pageStateToReset.viewStateAttribute], viewStateToAssign);
        }

        if (routerState[pageStateToReset.viewStateAttribute] != null && routerState[pageStateToReset.viewStateAttribute].applyFilters != null) {
            const viewState = Object.assign({}, routerState[pageStateToReset.viewStateAttribute]);
            viewState.applyFilters = pageStateToReset.mandatoryApplyFilterParam != null ? viewState.applyFilters.filter(applyFilter => applyFilter.param === pageStateToReset.mandatoryApplyFilterParam) : [];
            routerState[pageStateToReset.viewStateAttribute] = viewState;
        }
    });
    props.history.push(props.location.pathname, routerState);
    props.setPageState(pageState);
}