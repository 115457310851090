import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import history from './core/store/history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import reducers from "./core/store/reducers";
import thunk from 'redux-thunk';
import { syncMiddleware } from "redux-sync-reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


// Configure the persistence of the redux state
const persistConfig = {
    key: 'cicostool',
    storage,
}
const persistedReducer = persistReducer(persistConfig, reducers(history,"cicostoolSync"));

const store = createStore(connectRouter(history)(persistedReducer), composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk),
    applyMiddleware(syncMiddleware)
));

let persistor = persistStore(store)
export { store, persistor };