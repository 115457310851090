import React, { Component } from 'react'
import { parseString } from '../../functions/utility';
import intl from 'react-intl-universal';
import * as formatters from '../../functions/formatters';
import { getLocalizedProperty } from '../../LocaleUtils';
import { TextField } from 'office-ui-fabric-react';

class WagxCalculatedInput extends Component {

  constructor(props) {
    super(props);

    this.state = {};
    this.ndValue = intl.get("WagxCalculatedInput.N.D.").d("N.D.");
    this.timeout = null;
  }

  componentDidMount() {
    const updatedValue = this.getValue();
    this.props.onChange(null, this.props.field, updatedValue, this.props.formId);
  }

  getValue() {
    const parsedFunc = parseString(this.props.function, this.props.object);
    let value = this.ndValue;
    try {
      // eslint-disable-next-line
      value = eval(parsedFunc);
    } catch (e) {
      console.error(e);
    }
    return value;
  }

  componentDidUpdate(prevProps, prevState) {
    let calculateValue = false;
    if (this.props.dependendFields) {
      for (let i = 0; i < this.props.dependendFields.length; i++) {
        const dependField = this.props.dependendFields[i];
        if (prevProps.object[dependField] !== this.props.object[dependField]) {
          calculateValue = true;
          break;
        }
      }
    }

    if (calculateValue) {
      const updatedValue = this.getValue();
      if (prevProps.value !== updatedValue) {
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.props.onChange(null, this.props.field, updatedValue, this.props.formId);
        }, 100);

      }
    }
  }

  render() {
    let value = this.props.value;
    if (value == null || value === undefined) {
      value = this.getValue();
    }
    if (this.props.viewFormatter != null || this.props.formatter != null) {
      value = formatters[this.props.viewFormatter != null ? this.props.viewFormatter : this.props.formatter](value);
    }

    return <TextField
      prefix={this.props.prefix}
      suffix={this.props.suffix}
      readOnly={this.props.readOnly == null ? true : this.props.readOnly}
      disabled={this.props.disabled}
      id={"inp-" + this.props.field}
      label={getLocalizedProperty(this.props, 'label')}
      name={this.props.field}
      className={this.props.className}
      value={value}
      multiline={this.props.multiline != null ? this.props.multiline : false}
      placeholder={getLocalizedProperty(this.props, 'placeholder')}
    />;
  }
}

export default WagxCalculatedInput;