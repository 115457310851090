import React from 'react';

export const WagxWhiteSpace = (props) => {

  let arrayOfSpaces = [];
  for (let i = 0; i < props.numberOfSpaces; i++) {
    arrayOfSpaces.push(<React.Fragment key={i}>&nbsp;</React.Fragment>);
  }

  let output = (<React.Fragment>{arrayOfSpaces}</React.Fragment>);
  return (
    output
  );
}

export const calculatePreAndPostSpaces = (value) => {
  let initialSpace = 0;
  let finalSpace = 0;

  if (value === " ") {
    initialSpace = 1;
  } else {
    while (value.charAt(initialSpace) === " " && initialSpace < value.length) {
      initialSpace++;
    }
    for (let i = value.length - 1; value.charAt(i) === " " && i > -1; i--) {
      finalSpace++;
    }
  }

  return [initialSpace, finalSpace];
}

export default React.memo(WagxWhiteSpace);