import React from 'react';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import './Spinner.css';


const spinner = () => (
  <div className="SpinnerContentContainer">
    <div className="SpinnerContainer">
      <Spinner ariaLive="assertive" />
    </div>
  </div>
);

export default React.memo(spinner);