import * as actionTypes from '../actions/action-types';
import { parseParameter, updateObject } from '../utils';

const initialState = {
  appState: null,
  isCheckingAuthState: false,
  hasToResetPassword: false,
  passwordResetData: null,
  error: null,
  loading: false,
  loginTime: 0
};

const localeChanged = (state, action) => {
  document.cookie = "lang=" + action.currentLocale + ";path=/clever;expires=Fri, 31 Dec 9999 23:59:59 GMT";
  return updateObject(state, { currentLocale: action.currentLocale });
}

const loginReset = (state, action) => {
  return initialState;
}

const loginStart = (state, action) => {
  return updateObject(state, { appState: null, error: null, loading: true });
}

const loginSuccess = (state, action) => {
  return updateObject(state, { appState: action.appState, loading: false, isCheckingAuthState: false, loginTime: new Date().getTime() });
}

const loginFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const logout = (state, action) => {
  return initialState;
}

const setIsCheckingAuthState = (state, action) => {
  return updateObject(state, {
    isCheckingAuthState: true
  });
}

const forceResetPassword = (state, action) => {
  const updatedState = Object.assign({}, state);
  updatedState.hasToResetPassword = true;
  updatedState.passwordResetData = action.data;
  updatedState.loading = false;
  return updateObject(state, updatedState);
}

const resetState = (state, action) => {
  return initialState;
}

const updateView = (state, action) => {
  const updatedState = Object.assign({}, state);
  const parsedStructure = JSON.parse(action.newStructureView);
  const viewId = +(action.viewId);
  if (updatedState.appState.viewMap[viewId] != null) {
    // aggiorno la struttura della vista aggiornata
    updatedState.appState.viewMap[viewId].pStructure = parsedStructure;
    if (action.updatedViewIds != null && action.updatedViewIds.length > 0) {
      // cerco tutte le viste che hanno la vista aggiornata come master o child
      for (const key of action.updatedViewIds) {
        const currentView = updatedState.appState.viewMap[key];
        if (currentView != null) {
          // aggiorno la struttura
          if (currentView.masterView != null && currentView.masterView.id === viewId) {
            currentView.masterView.pStructure = parsedStructure;
          }
          if (currentView.childView != null && currentView.childView.id === viewId) {
            currentView.childView.pStructure = parsedStructure;
          }
        } else {
          console.error("Redux for parent view not updated! View with id [" + key + "] not found.");
        }
      }
    }
  } else {
    console.error("Redux not updated! View with id [" + viewId + "] not found.");
  }
  return updateObject(state, updatedState);
}

const changeCurrentUser = (state, action) => {
  const subUsers = [...state.appState.subUsers];
  const updatedCurrentUser = subUsers.find(subUser => subUser.id === action.userId);
  const updatedState = Object.assign({}, state);
  updatedState.appState.currentSubUser = updatedCurrentUser;
  return updateObject(state, updatedState);
}

const updateAllViewOnUserChanged = (state, action) => {
  const updatedState = Object.assign({}, state);
  const viewMap = [];
  //TODO FARE IL PARSE DELLE STRUTTURE
  for (let key in action.viewMap) {
    parseView(action.viewMap[key]);
    viewMap[key] = action.viewMap[key];
  }

  updatedState.appState.viewMap = viewMap;
  return updateObject(state, updatedState);
}

const parseView = (item) => {
  try {
    item.pStructure = JSON.parse(parseParameter(item.structure));
    item.structure = undefined;
    item.applyFilters = undefined; // resetto applyFilters della vista perché vengono già gestiti dal frontend JSON.parse(item.applyFilters);
  } catch (e) {
    console.error("Structure of view with id " + item.id + " is not parsable!", e);
  }
  try {
    if (item.masterView) {
      item.masterView.pStructure = JSON.parse(item.masterView.structure);
      item.masterView.structure = undefined;
    }
  } catch (e) {
    console.error("Structure of view with id " + item.masterView.id + " is not parsable!", e);
  }
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_CHECK_STATE_START: return setIsCheckingAuthState(state, action);
    case actionTypes.LOGIN_RESET: return loginReset(state, action);
    case actionTypes.LOGIN_START: return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL: return loginFail(state, action);
    case actionTypes.LOGOUT: return logout(state, action);
    case actionTypes.LOCALE_CHANGED: return localeChanged(state, action);
    case actionTypes.CURRENT_USER_CHANGED: return changeCurrentUser(state, action);
    case actionTypes.HAS_TO_RESET_PASSWORD: return forceResetPassword(state, action);
    case actionTypes.RESET_AUTH_STATE: return resetState(state, action);
    case actionTypes.UPDATE_VIEW: return updateView(state, action);
    case actionTypes.UPDATE_ALL_VIEW_ON_USER_CHANGED: return updateAllViewOnUserChanged(state, action);
    default:
      return state;
  }
};

export default reducer;