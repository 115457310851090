import React, { Component } from 'react';
import * as formatters from "../../../functions/formatters";
import './DataTablePagination.css';
import { CommandBar } from 'office-ui-fabric-react';
import intl from 'react-intl-universal';

class DataTablePagination extends Component {

  firstPageClickHandler = () => {
    this.props.onChangePage(0);
  };

  prevPageClickHandler = () => {
    const updatedPage = (this.props.page - 1) - 1;
    this.props.onChangePage(updatedPage);
  };

  nextPageClickHandler = () => {
    const updatedPage = (this.props.page + 1) - 1;
    this.props.onChangePage(updatedPage);
  };

  lastPageClickHandler = () => {
    this.props.onChangePage(
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };
  pageClickHandler = (event, item) => {
    const updatedPage = (item.key) - 1;
    this.props.onChangePage(updatedPage);
  }
  getPaginationItems() {
    const { count, page, rowsPerPage, totalPages } = this.props;
    const dataLoaded = this.props.dataLoaded;

    let xMin, xMax;

    if (page <= 3) {
      xMin = 1;
      xMax = Math.min(totalPages, 5);
    } else if (page >= (totalPages - 2)) {
      xMin = Math.max(totalPages - 4, 1);
      xMax = totalPages;
    } else {
      xMin = page - 2;
      xMax = page + 2;
    }
    let pages = [];
    let i = xMin;
    do {
      pages.push(i);
      i++;
    } while (i <= xMax)


    let paginationItems = pages.map((pageItem) => {
      return {
        key: "" + pageItem,
        name: "" + pageItem,
        iconOnly: false,
        disabled: page === pageItem || !dataLoaded,
        onClick: this.pageClickHandler,
        className: page === pageItem ? "ms-bgColor-themePrimary ms-fontColor-white" : null,
      }
    });

    let totalePagine = Math.ceil(count / rowsPerPage);
    if (!totalePagine || totalePagine === 0) {
      totalePagine = 1;
    }

    paginationItems = [
      {
        key: "first",
        name: intl.get("DataTablePagination.first").d("Prima"),
        iconProps: {
          iconName: 'DoubleChevronLeft'
        },
        iconOnly: true,
        disabled: page <= 1 || !dataLoaded,
        onClick: this.firstPageClickHandler

      }, {
        key: "prev",
        name: intl.get("DataTablePagination.previous").d("Precedente"),
        iconProps: {
          iconName: 'ChevronLeft'
        },
        iconOnly: true,
        disabled: page <= 1 || !dataLoaded,
        onClick: this.prevPageClickHandler

      }, ...paginationItems
      , {
        key: "next",
        name: intl.get("DataTablePagination.next").d("Successiva"),
        iconProps: {
          iconName: 'ChevronRight'
        },
        iconOnly: true,
        disabled: page === totalePagine || !dataLoaded,
        onClick: this.nextPageClickHandler

      }, {
        key: "last",
        name: intl.get("DataTablePagination.last").d("Ultima"),
        iconProps: {
          iconName: 'DoubleChevronRight'
        },
        iconOnly: true,
        disabled: page === totalePagine || !dataLoaded,
        onClick: this.lastPageClickHandler

      }
    ];
    return paginationItems;
  }
  getItems = () => {
    const items = [];
    const dataLoaded = this.props.dataLoaded;
    let text = "";
    if (!dataLoaded) {
      text = intl.get("DataTablePagination.loadInProgress").d("Caricamento in corso... Si prega di attendere");
    } else {

      if (this.props.showLastRefresh || this.props.showTotalItemsCount) {
        let maxItems = this.props.page * this.props.rowsPerPage;
        if (maxItems > this.props.count) {
          maxItems = this.props.count;
        }
        if (this.props.count !== 0) {
          const data = {
            results: formatters.intFormatter(((this.props.page - 1) * this.props.rowsPerPage) + 1),
            maxItems: formatters.intFormatter(maxItems),
            count: formatters.intFormatter(this.props.count),
            time: (this.props.loadTime ? this.props.loadTime / 1000 : "")
          };

          if (this.props.showTotalItemsCount && maxItems > 0) {
            if (this.props.showLastRefresh && this.props.lastRefreshDate) {
              data.data = (this.props.lastRefreshDate ? this.props.lastRefreshDate.toLocaleString() : "");
              if (this.props.loadTime) {
                text = intl.get("DataTablePagination.resultsWithMaxItemsCountDataAndTime", data).d("Risultati: {results} - {maxItems} di {count} Data esecuzione: {data} ({time} secondi)", data);
              } else {
                text = intl.get("DataTablePagination.resultsWithMaxItemsCountAndData", data).d("Risultati: {results} - {maxItems} di {count} Data esecuzione: {data}", data);
              }
            } else {
              if (this.props.loadTime) {
                text = intl.get("DataTablePagination.resultsWithMaxItemsCountAndTime", data).d("Risultati: {results} - {maxItems} di {count} ({time} secondi)", data);
              } else {
                text = intl.get("DataTablePagination.resultsWithMaxItemsAndCount", data).d("Risultati: {results} - {maxItems} di {count}", data);
              }
            }
          } else {
            if (this.props.showLastRefresh && this.props.lastRefreshDate) {
              data.data = (this.props.lastRefreshDate ? this.props.lastRefreshDate.toLocaleString() : "");
              if (this.props.loadTime) {
                text = intl.get("DataTablePagination.resultsWithDataAndTime", data).d("Risultati: {results} Data esecuzione: {data} ({time} secondi)", data);
              } else {
                text = intl.get("DataTablePagination.resultsWithData", data).d("Risultati: {results} Data esecuzione: {data}", data);
              }
            } else {
              if (this.props.loadTime) {
                text = intl.get("DataTablePagination.resultsWithTime", data).d("Risultati: {results} ({time} secondi)", data);
              } else {
                text = intl.get("DataTablePagination.results", data).d("Risultati: {results}", data);
              }
            }
          }
        } else {
          const data = {
            time: (this.props.loadTime ? this.props.loadTime / 1000 : "")
          };
          if (this.props.loadTime) {
            text = intl.get("DataTablePagination.noResultsWithTime", data).d("Nessun Risultato ({time} secondi)", data);
          } else {
            text = intl.get("DataTablePagination.noResults").d("Nessun Risultato");
          }
        }
      }
    }
    items.push({
      key: "totalElements",
      text: text,
      onRender: (item) => <p>{text}</p>
    });
    if (this.props && Array.isArray(this.props.pagerAddons)) {
      this.props.pagerAddons.forEach((pagerAddon, index) => {
        items.push({ key: "pagerAddon" + index, ...pagerAddon, onRender: this.renderPagerAddons });
      });
    }
    return items;
  }

  renderPagerAddons = (item) => {
    let val = 0;

    switch (item.type) {
      case "count":
        if (this.props.selectedItems !== undefined && this.props.selectedItems !== null) {
          val = this.props.selectedItems.length;
        }
        break;
      case "sum":
        if (this.props.selectedItems !== undefined && this.props.selectedItems !== null) {
          this.props.selectedItems.forEach(selectedItem => {
            val += selectedItem['liquidazione_totale'];
          });
        }
        break;
      default:
        break;
    }
    if (item.formatter !== null && item.formatter !== undefined) {
      val = formatters[item.formatter](val);
    }
    return (
      <p style={item.style}>{item.text} {val}</p>
    );
  }

  render() {
    const paginationItems = this.getPaginationItems();
    const items = this.getItems();

    return (
      <div className="DataTablePagination">
        <CommandBar farItems={paginationItems} items={items} ></CommandBar>
      </div>
    );
  }
}

export default DataTablePagination;