import { Component } from "react";

class WagBaseComponent extends Component {

  constructor(props) {
    super(props);
    this.structure = props.view && props.view.pStructure ? props.view.pStructure : props.structure;
    this.style = null;
  }

  componentDidMount() {
    if (this.structure && this.structure.reloadOnTabChange) {
      window.addEventListener("focus", this.refreshHandler);
    }
    this.mountCss();
  }

  componentDidUpdate(prevProps, prevState) {
    if( prevProps.cssStyle !== this.props.cssStyle){
      this.dismountCss();  
      this.mountCss();
    }
  }

  componentWillUnmount() {
    if (this.structure && this.structure.reloadOnTabChange) {
      window.removeEventListener("focus", this.refreshHandler);
    }
    this.dismountCss();
  }

  refreshHandler = () => { }

  mountCss = () => {
    if (this.style === null) {
      let cssStyle = null;
      let id = "UNK";
      if (this.props.cssStyle != null && this.props.cssStyle !== "") {
        cssStyle = this.props.cssStyle;
        id = (this.props.id ? this.props.id : (this.props.viewId ? this.props.viewId : "UNK"));
      } else if (this.props.view && this.props.view.cssStyle != null && this.props.view.cssStyle !== "") {
        cssStyle = this.props.view.cssStyle;
        id = (this.props.view.id ? this.props.view.id : (this.props.view.viewId ? this.props.view.viewId : "UNK"));
      }
      if (cssStyle) {
        this.style = document.createElement("style");
        this.style.innerHTML = '/* css from view with id ' + id + ' */ ' + cssStyle;
        document.head.appendChild(this.style);
      }
    }
  }

  dismountCss = () => {
    if (this.style !== null) {
      document.head.removeChild(this.style);
      this.style = null;
    }
  }
}

export default WagBaseComponent;