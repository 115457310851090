import * as actionTypes from '../actions/action-types';
import { updateObject } from '../utils';

const initialState = {
  hasError: false,
  idClientError: null,
  formCaption: null,
  formText: null
};
const setHasErrorAndIdClientError = (state, action) => {
  return updateObject(state, {
    hasError: action.hasError,
    idClientError: action.idClientError, 
    formCaption: action.formCaption,
    formText: action.formText });
}

const resetHasErrorAndIdClientError = (state, action) => {
  return resetState();
}

const resetState = (state, action) => {
  return {...initialState};
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT: return resetHasErrorAndIdClientError(state, action);
    case actionTypes.SET_HAS_ERROR_AND_IDCLIENTERROR: return setHasErrorAndIdClientError(state, action);
    case actionTypes.RESET_HAS_ERROR_AND_IDCLIENTERROR: return resetHasErrorAndIdClientError(state, action);
    default:
      return state;
  }
};

export default reducer;