import * as actionTypes from "./action-types";
export const setCurStartInterval = (calendar) => {
    return {
        type: actionTypes.SET_CUR_START_INTERVAL,
        calendar: calendar
    }
}
export const setSelectedCalendarData = (calendar) => {
    return {
        type: actionTypes.SET_SELECTED_CALENDAR_DATA,
        calendar: calendar
    }
}
export const setIsFlipped = (calendar) => {
    return {
        type: actionTypes.SET_IS_FLIPPED,
        calendar: calendar
    }
}