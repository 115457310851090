export const TEXT = "Text";
export const NUMBER = "Number";
export const TEXT_NO_UPDATE = "TextNoUpdate";
export const SELECT = "Select";
export const DATE = "Date";
export const DATE_RANGE = "DateRange";
export const CURRENCY_RANGE = "CurrencyRange";
export const TEXTAREA = "TextArea";
export const TINYMCE_TEXTAREA = "TinyMceTextArea";
export const CHECKBOX = "CheckBox";
export const RADIO = "RadioButton";
export const CODE = "Code";
export const CSSEDITOR = "CssEditor";
export const LABEL = "Label";
export const DATA_TABLE = "Data_table";
export const FILE_DROP_ZONE = "fileDropZone";
export const FILE = "file";
export const ACTION_BUTTON = "actionButton";
export const WAGX_ACTION = "WagxAction";
export const ACTION = "Action";
export const TOGGLE = "Toggle";
export const AUTOCOMPLETE = "Autocomplete";
export const CALCULATED_INPUT = "CalulatedInput";
export const LINK = "Link";
export const SLIDER = "Slider";
export const ICON = "Icon";
export const TEXT_BORDER = "textWithBorder";
export const VIEW = "View";
export const FORM_SUBMIT = "FormSubmit";
export const CONTEXT_MENU = "ContextMenu";
export const GOOGLE_PLACES_AUTOCOMLETE = "googlePlacesAutocomplete";
export const DYNAMIC = "Dynamic";
export const CURRENCY = "Currency";
export const ADVANCED_SELECT = "AdvSelect";
export const HIDDEN = "hidden";
export const HIDDEN_EMPTY = "";
export const DISPLAY_HIDDEN = "DISPLAY_HIDDEN";
export const DATE_TIME_PICKER = "DateTimePicker";