import React, { Component } from 'react';
import { IconButton, Callout, DirectionalHint } from 'office-ui-fabric-react';
import GenericInput from '../GenericInput/GenericInput';
import './ContextMenu.css';

class ContextMenu extends Component {
  constructor(props) {
    super(props);

    this.calloutIcon = <IconButton
      onClick={(event) => { /*event.stopPropagation();*/ this._onShowMenuClicked() }}
      iconProps={{ iconName: this.props.iconName }}
      title="Azioni" />;

    //Inizializzazione STATE

    this.state = {
      isCalloutVisible: false,
      interrogazioneAniaCalloutIndex: null,
      interrogazioneAniaCalloutObject: null,
      selectedKey: null
    };
  }


  render() {
    if (this.props.mode === "icon") {
      return (
        <div className="ContextMenuContainer">
          <div ref={idCallout => (this._idCallout = idCallout)} >
            {this.calloutIcon}
          </div>
          {this.state.isCalloutVisible ?
            <Callout
              target={this._idCallout}
              onDismiss={this._onCalloutDismiss}
              isBeakVisible={true}
              directionalHint={DirectionalHint.bottomLeftEdge}
              style={{ overflow: "hidden" }}
            >
              {this.props.inputs.map((input, index) => {
                input.object = this.props.object;
                return (
                  <div key={index} className={this.props.className + " ms-Grid"} dir="ltr" >
                    <div className="ms-Grid-row" >
                      <div className="ms-Grid-col ms-sm12 callout-button-container-first" >
                        <GenericInput
                          {...input}
                          onOpenViewClick={this.props.onOpenViewClick}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </Callout>
            : null}
        </div>
      );
    } else {
      return null;
    }
  }

  _onShowMenuClicked = () => {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible
    });
  };

  azioniTargaButtonClickHandler = (object/*, index*/) => {
    this.setState({
      //interrogazioneAniaCalloutIndex: index,
      interrogazioneAniaCalloutObject: object
    });
  }

  onRenderCellHandler = (item, showDetailHandler, structure, index) => {
    this.calloutIcon = <IconButton onClick={(event) => { event.stopPropagation(); this.azioniTargaButtonClickHandler(item.object/*, index*/) }}
      iconProps={{ iconName: this.props.iconName }}
      title="Azioni" />
  }

  _onCalloutDismiss = () => {
    this.setState({
      isCalloutVisible: !this.state.isCalloutVisible
    });
  }
}

export default ContextMenu;