import * as actionTypes from "./action-types";

export const setHasErrorAndIdClientError = (hasError, idClientError, formCaption, formText) => {
  return { 
    type: actionTypes.SET_HAS_ERROR_AND_IDCLIENTERROR,
    hasError: hasError,
    idClientError: idClientError,
    formCaption: formCaption,
    formText:  formText
  };
}
export const resetHasErrorAndIdClientError = () => {
  return { 
    type: actionTypes.RESET_HAS_ERROR_AND_IDCLIENTERROR
  };
}
