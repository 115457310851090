export const NAZIONE = "NAZIONE";
export const CODICE_NAZIONE = "CODICE_NAZIONE";
export const REGIONE = "REGIONE";
export const PROVINCIA = "PROVINCIA";
export const COMUNE = "COMUNE";
export const CAP = "CAP";
export const INDIRIZZO = "INDIRIZZO";
export const CIVICO = "CIVICO";
export const LAT = "LAT";
export const LNG = "LNG";
