import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { TooltipHost } from 'office-ui-fabric-react';
import CurrencyInput from './CurrencyInput';
import {getLocalizedProperty} from "../../LocaleUtils";

export default class CurrencyRangeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyRangeValue: new CurrencyRangeValue()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value && this.state.currencyRangeValue.toString() !== this.props.value) {
      let currencyRangeValue = new CurrencyRangeValue(this.retrieveCurrencyValue(this.props.value));
      this.setState({ currencyRangeValue: currencyRangeValue });
    }
  }

  retrieveCurrencyValue = (value) => {
    return value === 0 || value === "" ? null : value;
  }

  onChangeFrom = (value) => {
    let currencyRangeValue = new CurrencyRangeValue(this.state.currencyRangeValue.toString());
    currencyRangeValue.fromCurrency = this.retrieveCurrencyValue(value);
    this.setState({ currencyRangeValue: currencyRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.currencyRangeValue.toString(), null, this.props.formId);
    });
  }

  onBlurFrom = (value) => {
    let currencyRangeValue = new CurrencyRangeValue(this.state.currencyRangeValue.toString());
    currencyRangeValue.fromCurrency = this.retrieveCurrencyValue(value);

    if (currencyRangeValue.fromCurrency != null && currencyRangeValue.fromCurrency !== 0) {
      if (currencyRangeValue.toCurrency == null || currencyRangeValue.toCurrency === 0) {
        currencyRangeValue.toCurrency = currencyRangeValue.fromCurrency;
      } else if (currencyRangeValue.fromCurrency > currencyRangeValue.toCurrency) {
        currencyRangeValue.toCurrency = currencyRangeValue.fromCurrency;
      }
    }

    this.setState({ currencyRangeValue: currencyRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.currencyRangeValue.toString(), null, this.props.formId);
    });
  }

  onChangeTo = (value) => {
    let currencyRangeValue = new CurrencyRangeValue(this.state.currencyRangeValue.toString());
    currencyRangeValue.toCurrency = this.retrieveCurrencyValue(value);
    this.setState({ currencyRangeValue: currencyRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.currencyRangeValue.toString(), null, this.props.formId);
    });
  }

  onBlurTo = (value) => {
    let currencyRangeValue = new CurrencyRangeValue(this.state.currencyRangeValue.toString());
    currencyRangeValue.toCurrency = this.retrieveCurrencyValue(value);

    if (currencyRangeValue.toCurrency != null && currencyRangeValue.toCurrency !== 0) {
      if (currencyRangeValue.fromCurrency == null || currencyRangeValue.fromCurrency === 0) {
        currencyRangeValue.fromCurrency = currencyRangeValue.toCurrency;
      } else if (currencyRangeValue.toCurrency < currencyRangeValue.fromCurrency) {
        currencyRangeValue.fromCurrency = currencyRangeValue.toCurrency;
      }
    }

    this.setState({ currencyRangeValue: currencyRangeValue }, () => {
      this.props.onChange(undefined, this.props.field, this.state.currencyRangeValue.toString(), null, this.props.formId);
    });
  }

  render() {
    let fromCurrencyInput, toCurrencyInput = null;
    const currencyRangeValue = new CurrencyRangeValue(this.state.currencyRangeValue.toString());

    if (this.props.fromCurrencyProps != null) {
      const fromCurrencyProps = this.props.fromCurrencyProps;
      const parsedLabel = fromCurrencyProps.label != null
        ? intl.get(fromCurrencyProps.label).d(fromCurrencyProps.label)
        : null;
      const placeHolderClass = currencyRangeValue.fromCurrency != null && currencyRangeValue.fromCurrency !== "" ? "" : "PlaceHolder";
      const placeholder = getLocalizedProperty(fromCurrencyProps,"placeholder");

      fromCurrencyInput = (
        <TooltipHost content={placeholder}>
          <CurrencyInput
            {...fromCurrencyProps}
            id={"FROM_CURRENCY_" + this.props.field}
            parsedLabel={parsedLabel}
            value={currencyRangeValue.fromCurrency}
            defaultValue={null}
            placeHolderClass={placeHolderClass}
            showCleaner={true}
            onChange={(event, field, value) => this.onChangeFrom(value)}
            onBlur={(event, field, value) => this.onBlurFrom(value)}
          />
        </TooltipHost>
      );
    }

    if (this.props.toCurrencyProps != null) {
      const toCurrencyProps = this.props.toCurrencyProps;
      const parsedLabel = toCurrencyProps.label != null
        ? intl.get(toCurrencyProps.label).d(toCurrencyProps.label)
        : null;
      const placeHolderClass = currencyRangeValue.toCurrency != null && currencyRangeValue.toCurrency !== "" ? "" : "PlaceHolder";
      const placeholder = getLocalizedProperty(toCurrencyProps,"placeholder");

      toCurrencyInput = (
        <TooltipHost content={placeholder}>
          <CurrencyInput
            {...toCurrencyProps}
            id={"TO_CURRENCY_" + this.props.field}
            parsedLabel={parsedLabel}
            value={currencyRangeValue.toCurrency}
            defaultValue={null}
            placeHolderClass={placeHolderClass}
            showCleaner={true}
            onChange={(event, field, value) => this.onChangeTo(value)}
            onBlur={(event, field, value) => this.onBlurTo(value)}
          />
        </TooltipHost>
      );
    }

    return (
      this.props.inline === true
        ? (
          <div className="ms-Grid">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm6">
                {fromCurrencyInput}
              </div>
              <div className="ms-Grid-col ms-sm6">
                {toCurrencyInput}
              </div>
            </div>
          </div>
        )
        : (
          <React.Fragment>
            {fromCurrencyInput}
            {toCurrencyInput}
          </React.Fragment>
        )
    );
  }
}

export class CurrencyRangeValue {
  constructor(value) {
    this.fromCurrency = null;
    this.toCurrency = null;
    this.operator = null;

    if (value == null || typeof value !== "string") {
      return;
    }

    if (value.startsWith("BW(") && value.endsWith(")")) {
      value = value.substring(3, value.length - 1);
      value = value.split(";");
      this.fromCurrency = value[0];
      this.toCurrency = value[1];
      this.operator = "BW";
    } else if (value.startsWith("GTE(") && value.endsWith(")")) {
      value = value.substring(4, value.length - 1);
      this.operator = "GTE";
      value = value.split(";");
      this.fromCurrency = value[0];
      this.toCurrency = null;
    } else if (value.startsWith("LTE(") && value.endsWith(")")) {
      value = value.substring(4, value.length - 1)
      this.operator = "LTE";
      value = value.split(";");
      this.fromCurrency = null;
      this.toCurrency = value[0];
    }
  }

  toString = () => {
    let value = null;
    if (this.toCurrency != null && this.fromCurrency != null) {
      value = "BW(" + this.fromCurrency + ";" + this.toCurrency + ")";
    } else if (this.toCurrency == null && this.fromCurrency != null) {
      value = "GTE(" + this.fromCurrency + ")";
    } else if (this.fromCurrency == null && this.toCurrency != null) {
      value = "LTE(" + this.toCurrency + ")";
    }
    return value;
  }
}