import axiosLib from "axios";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";
import lodash from 'lodash';
import moment from 'moment-with-locales-es6';
import { CheckboxVisibility, CollapseAllVisibility, ColumnActionsMode, CommandBar, ContextualMenu, DefaultButton, DetailsList, DetailsListLayoutMode, DetailsRow, DetailsRowCheck, DirectionalHint, IconButton, Layer, MessageBar, MessageBarType, SearchBox, Selection, SelectionMode, TextField, TooltipHost } from 'office-ui-fabric-react';
import { ScrollablePane } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from "../../../config";
import axios from '../../axios-wagx-web';
import ViewContext from "../../containers/ViewContext/ViewContext";
import { MessageSeverityEnum } from "../../enums/MessageSeverityEnum";
import * as formatters from '../../functions/formatters';
import { clickLinkHandler, evaluateShowIfOnComponent, manageError, parseAdvancedTitle, parseString, parseStringFromApplyFilters, computeApplyFiltersParam, showMessageModal, manageResponseDataMessageList, buildMessage, computeFunctionBody } from '../../functions/utility';
import { getLocalizedProperty } from "../../LocaleUtils";
import * as actions from '../../store/actions';
import DatePicker from '../../UI/DatePicker/DatePicker';
import { DateRangeValue } from '../../UI/DatePicker/DateRangePicker';
import SelectField from "../../UI/SelectField/SelectField";
import Spinner from '../../UI/Spinner/Spinner';
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import * as actionTypes from '../CRUDComponent/Actions/ActionTypes';
import { getCommandBarActions, getDefaultActions, preActionOrPersist } from '../CRUDComponent/Actions/ActionUtils';
import { CurrencyRangeValue } from "../CurrencyInput/CurrencyRangeInput";
import GenericInput from "../GenericInput/GenericInput";
import { CURRENCY_RANGE, DATE_RANGE } from "../GenericInput/GenericInputTypes";
import WagBaseComponent from "../WagBaseComponent/WagBaseComponent";
import WagxExpandPanel from "../WagxExpandPanel/WagxExpandPanel";
import './DataTable.css';
import DataTablePagination from './DataTablePagination/DataTablePagination';
import DataTableToolbar from './DataTableToolbar/DataTableToolbar';
import * as DataTableTypes from './DataTableTypes';
import DataTableTd from './TableData/DataTableTd';


class DataTable extends WagBaseComponent {

  constructor(props) {
    super(props);
    this.asyncTaskTimeout = null;
    this.searchMode = this.props.searchMode;
    this.isInMultiselector = this.props.multiSelectorSideKey ? true : false;
    this.filterObjectHasSubmitted = false;

    this.DatePickerStrings = {
      isRequiredErrorMessage: 'Campo necessario'
    }

    //Eseguo operazioni differenti in base al tipo di ricerca impostato
    let loadData;
    switch (this.searchMode) {
      case DataTableTypes.FILTER:
        loadData = true;
        break;
      case DataTableTypes.SEARCH:
        loadData = false;
        break;
      default:
        loadData = true;
        break;
    }

    let dataLoaded = null;
    if (!props.loadData && props.loadData !== undefined) {
      dataLoaded = true;
    } else {
      dataLoaded = (props.data || !loadData) ? true : false;
    }

    if (props.data == null && props.viewState && props.viewState.filters && Object.keys(props.viewState.filters).length > 0) {
      dataLoaded = false;
    }

    if (props.getLoadedObject) {
      dataLoaded = true;
    }

    let order = this.props.order ? this.props.order : 'asc';
    let orderBy = this.props.orderBy ? this.props.orderBy : '';


    const page = {
      number: props.pagination ? props.pagination.pageNumber : 0,
      size: props.pagination ? props.pagination.pageSize : 0,
      ...this.props.pagination
    }


    this.state = {
      tableData: props.data ? [...props.data] : [],
      loading: !dataLoaded,
      length: props.data ? props.data.length : 0,
      page: page,
      order: order,
      orderBy: orderBy,
      dataLoaded: dataLoaded,
      selectedItem: null,
      showDeleteDialog: false,
      exportInProgress: false,
      downloadInProgress: false,
      changeSwitchIconInProgress: false,
      sendDataConfirmModal: null,
      items: props.data != null ? this.createItemsArray(props.data) : [],
      groups: (props.groupedList && props.data != null) ? this.createGroupsArray(props.data) : null,
      filterObject: {},
      selectedItems: [],
      parsedTitle: props.parsedTitle,
      persistedFilterLoaded: false,
      liveFilterString: "",
      liveFilterStringConsolidated: ""
    };

    this.selectionMode = SelectionMode.none;
    if (this.props.selectionEnabled) {
      switch (this.props.selectionMode) {
        case "none":
          this.selectionMode = SelectionMode.none;
          break;
        case "single":
          this.selectionMode = SelectionMode.single;
          break;
        case "multiple":
          this.selectionMode = SelectionMode.multiple;
          break;
        default:
          if (this.props.isSingleSelection === true) {
            this.selectionMode = SelectionMode.single;
          } else {
            this.selectionMode = SelectionMode.multiple;
          }
          break;
      }
    }

    let filterReplacementMap = {
      param: "totalElements",
      value: this.state.dataLoaded ? (this.state.page.totalElements == null ? "" + this.state.tableData.length : "" + this.state.page.totalElements) : "0"
    };

    this.rangeStrings = [intl.get("DatePickerRange.from"), intl.get("DatePickerRange.to")];

    this.evaluetedShowIf = evaluateShowIfOnComponent(this.props.showIf, this.props.viewState ? this.props.viewState.applyFilters : null, filterReplacementMap);

    this._selection = new Selection({
      onSelectionChanged: this.onItemsSelectionChangedHandler,
      selectionMode: this.props.selectionEnabled === null || this.props.selectionEnabled ? this.props.isSingleSelection ? SelectionMode.single : SelectionMode.multiple : SelectionMode.none
    });

    this.discardedSelectedItemsPreference = [];

    this._selectionChangeDisabled = false;
    this.selectedItemsPreference = [];
    if (props.preference && props.preference.selectedItems) {
      this.selectedItemsPreference = props.preference.selectedItems;
    }


    this.state.columns = this.createColumnsArray(props, this.state);

    if (this.props.viewState != null && this.props.viewState.filters != null) {
      for (const key in this.props.viewState.filters) {
        if (this.props.viewState.filters.hasOwnProperty(key)) {
          const element = this.props.viewState.filters[key];
          this.state.filterObject[key] = { value: element.value, type: element.type, filterType: element.filterType };
        }
      }
    }

    this._isMounted = false;

    this.preference = null;
    if (this.props.preference != null) {
      this.preference = this.props.preference;
    } else if (this.props.pStructure.preference != null) {
      this.preference = this.props.pStructure.preference;
    }
  }

  onBroadcastChannelEventListener = (event) => {
    try {
      if (event.data) {
        switch (event.data.wagxCommand) {
          case "refresh":
            if (event.data.viewId === this.props.view.id) {
              //è giusto avere il singolo uguale perché su alcune dataTable l'objectId è una stringa e se gli passiamo una stringa il controllo non viene passato
              // eslint-disable-next-line
              if (event.data.objectId != null && event.data.objectId != this.props.objectId) {
                return;
              }
              console.log("REFRESH from wagxBroadcastChannel", event);
              const showSpinner = event.data.showSpinner === false ? false : true;
              this.loadData(null, showSpinner);
            }
            break;
          default:
            break;
        }
      }
    } catch (e) {
      console.error(e);
      // NOTING TO DO
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.filterObjectHasSubmitted = this.computeAndSubmitFilterObject();

    if (window.wagxBroadcastChannel) {
      window.wagxBroadcastChannel.addEventListener("message", this.onBroadcastChannelEventListener, false);
    }
    this._isMounted = true;

    if (!this.state.dataLoaded && !this.filterObjectHasSubmitted) {

      let restBaseUrl = this.props.restBaseUrl;

      if (this.props.persistFilter) {
        axios.get(restBaseUrl + "/persistFilter").then(response => {
          if (response.data.value) {
            var order = response.data.value.sort[0].split(',')
            delete response.data.value.sort;
            var projection = response.data.value.projection;
            delete response.data.value.projection;

            var filterObj = {}
            for (var obj in response.data.value) {
              filterObj[obj] = {}
              Object.assign(filterObj[obj], { value: response.data.value[obj][0], type: null });
            }

            this.props.filterListHandler(filterObj);
            this.setState({
              projection: [...projection],
              orderBy: order[0],
              oredr: order[1],
              filterObject: { ...filterObj },
              persistedFilterLoaded: true,
              dataLoaded: false,
              loading: false
            });
          } else {
            this.setState({
              persistedFilterLoaded: true,
              dataLoaded: false,
              loading: false
            });
          }
        });
      } else {
        this.loadData();
      }
    } else if (this.props.data != null && this.props.selectionEnabled === true) {
      this._selection.setItems(this.state.items);
      this.setDefaultSelection();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (window.wagxBroadcastChannel) {
      window.wagxBroadcastChannel.removeEventListener("message", this.onBroadcastChannelEventListener, false);
    }
    this._isMounted = false;
    if (this.requestCancelToken != null) {
      this.requestCancelToken.cancel();
      this.requestCancelToken = null;
    }
    if (this.asyncTaskTimeout) {
      clearTimeout(this.asyncTaskTimeout);
    }
    if (this.autoRefreshHandler) {
      clearTimeout(this.autoRefreshHandler);
      this.autoRefreshHandler = null;
    }
    if (this.onItemsSelectionChangedTimeout) {
      clearTimeout(this.onItemsSelectionChangedTimeout);
      this.onItemsSelectionChangedTimeout = null;
    }
    if (this.liveFilterTimeout != null) {
      clearTimeout(this.liveFilterTimeout);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data && this.isInMultiselector && this.state.tableData.length !== nextProps.data.length) {
      let items = this.createItemsArray([...nextProps.data]);
      this.setState({ tableData: [...nextProps.data], items: items, length: nextProps.data.length, parsedTitle: nextProps.parsedTitle })
    } else if (nextProps.data && nextProps.forceData === true) {
      let items = this.createItemsArray([...nextProps.data]);
      this.setState({ tableData: [...nextProps.data], items: items, length: nextProps.data.length, parsedTitle: nextProps.parsedTitle })
    }
    if (nextProps.pagination != null && nextProps.forceData === true) {
      this.setState({ page: { ...this.state.page, ...nextProps.pagination } });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    let shouldLoadData = false;
    let showSpinner = true;

    if (!this.props.getLoadedObject) {
      if (this.props.pStructure.refreshOnFiltersChange === true || this.props.pStructure.refreshOnFiltersChange == null) {
        if (this.props.viewState && prevProps.viewState && this.props.viewState.filters && prevProps.viewState.filters) {
          if (lodash.isEqual(this.props.viewState.filters, prevProps.viewState.filters) === false) {
            shouldLoadData = true;
          }
        }
      }

      if (this.props.reloadComponent || (!this.state.dataLoaded && !this.state.loading)) {
        shouldLoadData = true;
      }
    }
    if (this.props.dataTable[this.props.id]) {
      if (this.props.dataTable[this.props.id].reload === true || this.props.dataTable[this.props.id].refresh === true) {
        shouldLoadData = true;
        showSpinner = this.props.dataTable[this.props.id].showSpinner === false ? false : true;

        let dataTableState = { ...this.props.dataTable };
        dataTableState[this.props.id] = undefined;

        this.props.setDataTableState(dataTableState);
      }
    }

    if (this.filterObjectHasSubmitted === true && !this.state.dataLoaded && this.state.loading) {
      this.filterObjectHasSubmitted = false;
      shouldLoadData = true;
    }

    if (shouldLoadData) {
      this.loadData(null, showSpinner);
    }

    if (prevProps.entity !== this.props.entity || prevProps.applyFilters !== this.props.applyFilters) {
      this.setState({ dataLoaded: false });
    }

    if (this.state.length !== prevState.length) {
      this._selection.setItems(this.state.items);
    }

    if (this.props.isSelectionResetting === true && prevProps.isSelectionResetting === false) {
      this._selection.getSelectedIndices().forEach(index => {
        if (this._selection.isIndexSelected(index) === true) {
          this._selection.setIndexSelected(index, false);
        }
      });
      this.props.onPersistUserPreferenceHandler([]);
      this.setState({ selectedItems: [] });
    }

    if (prevState.dataLoaded === false && this.state.dataLoaded === true) {
      if (this.props.afterDataLoaded) {
        this.props.afterDataLoaded();
      }
    }

    if (!lodash.isEqual(this.props.filterObject, prevProps.filterObject)) {
      this.computeAndSubmitFilterObject();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let filterReplacementMap = {
      param: "totalElements",
      value: this.state.dataLoaded ? (this.state.page.totalElements == null ? "" + this.state.tableData.length : "" + this.state.page.totalElements) : "0"
    };

    this.evaluetedShowIf = evaluateShowIfOnComponent(nextProps.showIf, this.props.viewState ? this.props.viewState.applyFilters : null, filterReplacementMap);
    let createColumns = false;
    if (!this.props.getLoadedObject) {
      if (nextProps.viewState && this.props.viewState) {
        if (
          nextProps.data == null &&
          (
            nextProps.viewState.page !== this.props.viewState.page
            || nextProps.viewState.applyFilters !== this.props.viewState.applyFilters
            || !lodash.isEqual(nextProps.viewState, this.props.viewState)
          )
        ) {
          createColumns = true;
          nextState.dataLoaded = false;
        }
        if (nextProps.viewState.filters !== this.props.viewState.filters) {
          if (nextProps.viewState != null && nextProps.viewState.filters != null) {
            for (const key in nextProps.viewState.filters) {
              if (nextProps.viewState.filters.hasOwnProperty(key)) {
                const element = nextProps.viewState.filters[key];
                nextState.filterObject[key] = { value: element.value, type: element.type, filterType: element.filterType };
              }
            }
          }
          createColumns = true;
          nextState.dataLoaded = false;
        }
      } else if (nextProps.viewStateAttribute != null && nextProps.viewState != null && nextProps.viewState[nextProps.viewStateAttribute] != null && this.props.viewState == null) {
        nextState.dataLoaded = false;
      }
      // se non mi stai passando i dati alllora faccio questo giro.
      if (this.props.data == null && (this.props.id !== nextProps.id || (nextProps.viewState && this.props.viewState && (nextProps.viewState.applyFilters !== this.props.viewState.applyFilters)))) {
        //arrivo in tabella da un link diverso ma con stessa vista oppure cambio vista: devo resettare
        createColumns = true;
        if (this.requestCancelToken != null) {
          this.requestCancelToken.cancel();
          this.requestCancelToken = null;
          nextState.dataLoaded = false;
          nextState.loading = false;
        }
        if (this.props.persistFilter) {
          nextState.filterObject = Object.assign({}, this.state.filterObject);
        } else {
          nextState.filterObject = {};
        }
        nextState.items = [];
        nextState.groups = [];
        nextState.orderBy = '';
        nextState.page = {
          number: nextProps.pagination ? nextProps.pagination.pageNumber : 0,
          size: nextProps.pagination ? nextProps.pagination.pageSize : 0
        };
        this._selection = new Selection({
          onSelectionChanged: this.onItemsSelectionChangedHandler
        });
      }
      if (nextState.orderBy !== this.state.orderBy
        || nextState.order !== this.state.order) {
        createColumns = true;
      }
      if (createColumns === false) {
        if (!lodash.isEmpty(this.state.filterObject)) {
          createColumns = true;
        }
      }
      if (createColumns) {
        nextState.columns = this.createColumnsArray(nextProps, nextState);
      }
    }
  }

  sendDataConfirmDialog = (currentAction, object, callback = null) => {
    const updatedSendDataConfirmDialog = {
      currentAction: currentAction,
      object: object,
      callback: callback
    };
    this.setState({
      sendDataConfirmModal: updatedSendDataConfirmDialog,
      showConfirmModal: true
    });
  }

  sendDataPreActionDialog = (currentAction, object) => {
    const updatedSendDataPreActionDialog = {
      currentAction: currentAction,
      object: object
    };
    this.setState({ sendDataPreActionDialog: updatedSendDataPreActionDialog });
  }

  checkAsyncTask = (actionProps, idTask, returnsFile) => {
    this.asyncTaskTimeout = setTimeout(() => {
      this.asyncTaskTimeout = null;
      axios.post("views/" + this.props.id + "/" + actionProps.asyncTaskFunctionName, { idTask: idTask }).then((response) => {
        if (response.data.success) {
          switch (response.data.value.status) {
            case "UNSTARTED":
              this.checkAsyncTask(actionProps, idTask, returnsFile);
              break;
            case "INPROGRESS":
              let remainTime = "(Calcolo in corso)";
              if (response.data.value.estimatedTimeRemaining !== null) {
                const dur = moment.duration(response.data.value.estimatedTimeRemaining * 1000);
                remainTime = dur.humanize();
              }
              this.props.showLongOperationWaitingModal(response.data.value.percent, "" + response.data.value.percent + "%", intl.get("AsyncTask.tempoRimanente", { remainTime: remainTime }).d("Tempo rimanente: " + remainTime));
              this.checkAsyncTask(actionProps, idTask, returnsFile);
              break;
            case "DONE":
              this.props.showLongOperationWaitingModal(100);
              this.props.hideLongOperationWaitingModal();
              if (returnsFile) {
                this.manageDownloadFile(response.data.value.resultBlob, response.data.value.resultString, "application/octect-stream");
              }

              if (response.data.value.responseMessages != null) {
                let responseMessages = response.data.value.responseMessages;
                try {
                  responseMessages = JSON.parse(response.data.value.responseMessages);
                } catch (e) {
                  console.error("ResponseMessage del task non è un responseMessage." + responseMessages, e);
                }
                if (Array.isArray(responseMessages) && responseMessages.length > 0) {
                  showMessageModal(true, null, responseMessages, true);
                }
              }
              break;
            case "ERROR":
              this.props.showLongOperationWaitingModal(100);
              this.props.hideLongOperationWaitingModal();
              if (response.data.value.responseMessages != null) {
                let responseMessages = response.data.value.responseMessages;
                try {
                  responseMessages = JSON.parse(response.data.value.responseMessages);
                } catch (e) {
                  console.error("ResponseMessage del task non è un responseMessage." + responseMessages, e);
                }
                if (Array.isArray(responseMessages) && responseMessages.length > 0) {
                  showMessageModal(true, null, responseMessages, true);
                }
              }
              break;
            default:
              this.checkAsyncTask(actionProps, idTask, returnsFile);
              break;
          }
        } else {
          this.props.hideLongOperationWaitingModal();
          if (response.data.responseMessages) {
            showMessageModal(response.data.success, null, response.data.responseMessages, true);
          }
        }
      }).catch((exception) => {
        if (!axiosLib.isCancel(exception)) {
          manageError("DataTable", null, exception,
            intl.get("DataTable.error.actionFailed.caption").d("Errore eseguendo un azione"),
            intl.get("DataTable.error.actionFailed.text").d("Si è verificato un errore generico eseguendo un azione. Se il problema persiste effettuare i logout e riprovare.")
          );
        }
        this.props.hideLongOperationWaitingModal();
      });
    }, actionProps.asyncTasktCheckTimeout ? actionProps.asyncTasktCheckTimeout : 500);
  }
  manageDownloadFile = (fileBody, fileName, contentType) => {
    const byteCharacters = atob(fileBody);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    fileDownload(blob, fileName);
  }

  manageActionsOnSuccess = (actionProps, response) => {
    if (actionProps != null && actionProps.updateComponentOnSuccess != null) {
      let componentState = {};
      actionProps.updateComponentOnSuccess.forEach(state => {
        if (componentState[state.componentViewStateAttribute] == null) {
          componentState[state.componentViewStateAttribute] = { ...state.viewState };
        }
        if (state.viewStateAttribute != null) {
          componentState[state.componentViewStateAttribute][state.viewStateAttribute] = state.viewState;
        }
      });
      this.props.setCustomComponentState(componentState);
    }

    if (actionProps != null && actionProps.updateDataTableOnSuccess != null) {
      let dataTableState = {};
      actionProps.updateDataTableOnSuccess.forEach(state => {
        let viewId = null;
        if (state.getViewIdFromUrl === true) {
          const path = this.props.location.pathname;
          viewId = path.substr((path.lastIndexOf("/") + 1), path.length);
        } else {
          viewId = state.viewId
        }
        if (dataTableState[viewId] == null) {
          dataTableState[viewId] = {};
        }
        dataTableState[viewId] = state.viewState
      });
      this.props.setDataTableState(dataTableState);
    }

    if (this.props.pStructure.updatePageOnSuccess != null) {
      let pageState = {};
      this.props.pStructure.updatePageOnSuccess.forEach(state => {
        if (pageState[state.pageViewStateAttribute] == null) {
          pageState[state.pageViewStateAttribute] = {};
        }
        if (state.viewStateAttribute) {
          pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
        } else {
          pageState[state.pageViewStateAttribute] = state.viewState
        }
      });
      this.props.setPageState(pageState);
    }

    if (actionProps != null && actionProps.updateDataTableOnSuccess != null) {
      let dataTableState = {};
      actionProps.updateDataTableOnSuccess.forEach(state => {
        let viewId = null;
        if (state.getViewIdFromUrl === true) {
          const path = this.props.location.pathname;
          viewId = path.substr((path.lastIndexOf("/") + 1), path.length);
        } else {
          viewId = state.viewId
        }
        if (dataTableState[viewId] == null) {
          dataTableState[viewId] = {};
        }
        dataTableState[viewId] = state.viewState
      });
      this.props.setDataTableState(dataTableState);
    }

    if (actionProps != null && actionProps.navigateOnSuccess != null) {
      let routerState = undefined;
      if (actionProps.keepRouterState) {
        routerState = Object.assign({}, this.context.location != null ? this.context.location.state : this.props.location.state);
      }
      this.props.history.push(parseString(actionProps.navigateOnSuccess, response.data.value), routerState);
    }
  }
  manageActionAnyways = (actionProps, object) => {
    if (actionProps == null) {
      return true;
    }

    let loadData = actionProps.loadData != null ? actionProps.loadData !== false : true;

    if (actionProps.updatePageOnSuccess != null) {
      let pageState = {};
      actionProps.updatePageOnSuccess.forEach(state => {
        if (pageState[state.pageViewStateAttribute] == null) {
          pageState[state.pageViewStateAttribute] = {};
        }
        pageState[state.pageViewStateAttribute][parseString(state.viewStateAttribute, object)] = state.viewState;
      });
      this.props.setPageState(pageState);
      loadData = false;
    }

    if (actionProps.updatePivotOnSuccess != null) {
      let pivotState = {};
      actionProps.updatePivotOnSuccess.forEach(state => {
        if (state.viewStateAttribute == null) {
          pivotState[state.pivotViewStateAttribute] = state.viewState;
        } else {
          if (pivotState[state.pivotViewStateAttribute] == null) {
            pivotState[state.pivotViewStateAttribute] = {};
          }
          pivotState[state.pivotViewStateAttribute][state.viewStateAttribute] = state.viewState;
        }
      });
      this.props.setPivotState(pivotState);
      loadData = false;
    }

    if (actionProps.closeModalOnSuccess) {
      this.props.hideSearchModal();
    }

    return loadData;
  }

  buildActionData = (currentAction, object) => {
    if (currentAction.fieldList != null) {
      const selectedItemFields = {};
      currentAction.fieldList.forEach(field => {
        selectedItemFields[field] = [];
        this._selection.getSelectedIndices().forEach(selectedIndex => {
          const selectedItem = this._selection.getItems()[selectedIndex];
          if (this.props.groupedList) {
            selectedItemFields[field].push(selectedItem.item[Object.keys(selectedItem.item)[0]].props.object[field]);
          } else {
            selectedItemFields[field].push(selectedItem[Object.keys(selectedItem)[0]].props.object[field]);
          }
        });
      });
      if (currentAction.applyFiltersFields != null) {
        currentAction.applyFiltersFields.forEach(field => {
          this.props.viewState.applyFilters.forEach(element => {
            if (element.param === field) {
              selectedItemFields[field] = element.value
            }
          });
        })
      }
      return selectedItemFields;
    } else {
      return object;
    }
  }

  getSelectedRows = (fieldList) => {
    const selectedItemFields = {};
    fieldList.forEach(field => {
      selectedItemFields[field] = [];
      this._selection.getSelectedIndices().forEach(selectedIndex => {
        const selectedItem = this._selection.getItems()[selectedIndex];
        if (this.props.groupedList) {
          selectedItemFields[field].push(selectedItem.item[Object.keys(selectedItem.item)[0]].props.object[field]);
        } else {
          selectedItemFields[field].push(selectedItem[Object.keys(selectedItem)[0]].props.object[field]);
        }
      });
    });
    return selectedItemFields;
  }

  sendData = (currentAction, object) => {
    if (this._selection.getSelectedIndices().length > 0 || object != null) {
      if (currentAction.preAction === true) {
        let data = this.buildActionData(currentAction, object);
        let url = "views/PreAction/" + this.props.id + "/" + currentAction.functionName;
        this.setState({
          showConfirmModalRequestInProgress: true
        }, () => {
          let keyLocalization = "CRUDComponent.validationFailed.title";
          preActionOrPersist(keyLocalization, data, url, () => {
            this.setState({
              showConfirmModalRequestInProgress: false
            }, () => {
              this.performSendData(currentAction, object);
            });
          }, (title, message, showConfirmModal = true) => {
            this.setState({
              showConfirmModal: showConfirmModal,
              confirmMessage: message,
              confirmTitle: title,
              afterConfirmParams: true,
              showConfirmModalRequestInProgress: false
            });
          });
        });
      } else {
        this.performSendData(currentAction, object);
      }
    } else {
      const message = buildMessage(MessageSeverityEnum.INFO, "DataTable.info.selectSomeRows.text");
      showMessageModal(false, null, message, false);
    }
  }

  performSendData = (currentAction, object) => {
    const asyncTask = currentAction.asyncTask ? currentAction.asyncTask : false;
    const returnsFile = currentAction.returnsFile ? currentAction.returnsFile : false;
    const data = this.buildActionData(currentAction, object);
    if (this._selection.getSelectedIndices().length > 0 || object != null) {
      currentAction.actionProps && currentAction.actionProps.doInBackground ? this.setState({ exportInProgress: true }) : this.props.showLongOperationWaitingModal();

      const postConfig = {};
      if (currentAction.handlingMsgError) {
        axios.post("views/" + this.props.id + "/" + currentAction.functionName, data, postConfig)
          .then(response => {
            if (asyncTask) {
              if (response.data.success) {
                const idTask = response.data.value;
                this.checkAsyncTask(currentAction.actionProps, idTask, returnsFile);
              } else {
                if (response.data.responseMessages) {
                  showMessageModal(response.data.success, null, response.data.responseMessages, true);
                }
              }
            } else {

              this.props.hideLongOperationWaitingModal();
              if (response.data.success) {
                if (returnsFile) {
                  this.manageDownloadFile(response.data.fileBody, contentDisposition.parse(response.data.contentDisposition).parameters["filename"], response.data.contentType);
                }
                this.manageActionsOnSuccess(currentAction.actionProps, response);
              }

              if (currentAction?.actionProps?.doInBackground !== true || response.data.success === false) {
                showMessageModal(response.data.success, null, response.data.responseMessages, true);
              }

              if (this.manageActionAnyways(currentAction.actionProps, object)) {
                this.loadData();
              }
            }
            currentAction.actionProps && currentAction.actionProps.doInBackground ? this.setState({ exportInProgress: false }) : this.props.hideLongOperationWaitingModal();
          }).catch(exception => {
            if (!axiosLib.isCancel(exception)) {
              manageError("DataTable", null, exception,
                intl.get("DataTable.error.actionFailed.caption").d("Errore eseguendo un azione"),
                intl.get("DataTable.error.actionFailed.text").d("Si è verificato un errore generico eseguendo un azione. Se il problema persiste effettuare i logout e riprovare.")
              );
            }
            currentAction.actionProps && currentAction.actionProps.doInBackground ? this.setState({ exportInProgress: false }) : this.props.hideLongOperationWaitingModal();
            this.props.hideLongOperationWaitingModal();
          });
      } else {
        if (returnsFile && !asyncTask) {
          postConfig.responseType = "blob";
        }
        axios.post("views/" + this.props.id + "/" + currentAction.functionName, data, postConfig)
          .then(response => {
            if (asyncTask) {
              if (response.data.success) {
                const idTask = response.data.value;
                this.checkAsyncTask(currentAction, idTask, returnsFile);
              } else {
                this.props.hideLongOperationWaitingModal();
                if (response.data.responseMessages) {
                  showMessageModal(response.data.success, null, response.data.responseMessages, true);
                }
              }
            } else {
              currentAction.actionProps && currentAction.actionProps.doInBackground ? this.setState({ exportInProgress: false }) : this.props.hideLongOperationWaitingModal();
              if (returnsFile) {
                if (response.data.type === "application/json") {
                  const json = JSON.parse(response.data);

                  if (json.success === false) {
                    showMessageModal(response.data.success, null, response.data.responseMessages, true);
                  }

                } else {
                  fileDownload(response.data, contentDisposition.parse(response.headers["content-disposition"]).parameters["filename"]);
                }
              } else {
                if (response.data.success) {
                  this.manageActionsOnSuccess(currentAction.actionProps, response);
                }

                if (currentAction?.actionProps?.doInBackground !== true || response.data.success === false) {
                  showMessageModal(response.data.success, null, response.data.responseMessages, true);
                }

                if (this.manageActionAnyways(currentAction.actionProps, object)) {
                  this.loadData();
                }
              }
            }
          }).catch(exception => {
            if (!axiosLib.isCancel(exception)) {
              manageError("DataTable", null, exception,
                intl.get("DataTable.error.actionFailed.caption").d("Errore eseguendo un azione"),
                intl.get("DataTable.error.actionFailed.text").d("Si è verificato un errore generico eseguendo un azione. Se il problema persiste effettuare i logout e riprovare.")
              );
            }
            currentAction.actionProps && currentAction.actionProps.doInBackground ? this.setState({ exportInProgress: false }) : this.props.hideLongOperationWaitingModal();
            this.props.hideLongOperationWaitingModal();
          });
      }
    } else {
      const message = buildMessage(MessageSeverityEnum.INFO, "DataTable.info.selectSomeRows.text");
      showMessageModal(false, null, message, false);
    }
    if (this.state.sendDataConfirmModal != null || this.state.showConfirmModal) {
      this.setState({
        sendDataConfirmModal: null,
        sendDataPreActionDialog: null,
        confirmMessage: null,
        showConfirmModal: false
      });
    }
  }

  prepareEngineWorkflow = (actionProps, object = null) => {
    if (actionProps == null || actionProps.workflowProps == null || actionProps.workflowProps.fieldToIdPratica == null) {
      console.warn("prepareEngineWorkflow not properly defined, object and workflowProps cannot be null/undefined!");
      return;
    }
    if (object != null) {
      // invoked by DataTableTd
      const kPratica = object[actionProps.workflowProps.fieldToIdPratica];
      this.invokeEngineWorkflow(kPratica, actionProps);
    } else {
      // invoked by Action
      const { workflowProps } = actionProps;
      const fieldToIdPratica = workflowProps.fieldToIdPratica;
      const buildProps = { fieldList: [fieldToIdPratica] };
      const kPraticaList = this.buildActionData(buildProps, null)[fieldToIdPratica];
      this.invokeEngineWorkflow(kPraticaList, actionProps);
    }
  }

  invokeEngineWorkflow = (kPraticaList, actionProps) => {
    if (!Array.isArray(kPraticaList)) {
      kPraticaList = [kPraticaList];
    }
    const { workflowProps } = actionProps;
    const wagxEngineParams = {
      reloadDataTableOnSuccess: actionProps.reloadDataTableOnSuccess,
      wagxEngineModel: {
        kPraticaList: kPraticaList,
        cTipoPratica: workflowProps.cTipoPratica,
        cGruppo: workflowProps.cGruppo
      }
    };
    if (kPraticaList == null || kPraticaList.length === 0) {
      const message = buildMessage(MessageSeverityEnum.INFO, "DataTable.info.selectSomeRows.text");
      showMessageModal(false, null, message, false);
    } else {
      if (actionProps.needUserConfirm === true) {
        let title = actionProps.confirmTitle ? intl.get(actionProps.confirmTitle).d(actionProps.confirmTitle) : "";
        let message = intl.get(actionProps.confirmMessage).d(actionProps.confirmMessage);
        this.setState({
          showConfirmModal: true,
          confirmMessage: message,
          confirmTitle: title,
          wagxEngineParams: wagxEngineParams,
          onConfirmCallback: () => this.performEngineWorkflow(wagxEngineParams)
        });
      } else {
        this.performEngineWorkflow(wagxEngineParams);
      }
    }
  }

  performEngineWorkflow = (wagxEngineParams) => {
    const { wagxEngineModel, reloadDataTableOnSuccess } = wagxEngineParams;
    this.setState({
      showConfirmModalRequestInProgress: true
    }, () => {
      const url = "/wagx_engine/workflow";
      axios.post(url, wagxEngineModel)
        .then(response => {
          this.setState({ showConfirmModalRequestInProgress: false });
          if (reloadDataTableOnSuccess === true) {
            this.refreshHandler();
          }
          // one of kPratica throw an exception
          if (!response.data.success && response.data.responseMessages) {
            showMessageModal(response.data.success, null, response.data.responseMessages, true);
          }
        }).catch(exception => {
          if (!axiosLib.isCancel(exception)) {
            manageError("DataTable", null, exception,
              intl.get("DataTable.error.engineWorkflow.caption").d("Fallita chiamata al motore."),
              intl.get("DataTable.error.engineWorkflow.text").d("Si è verificato durante la chiamata al motore.")
            );
          }
          this.setState({ showConfirmModalRequestInProgress: false });
        });

      if (this.state.wagxEngineModel != null || this.state.showConfirmModal) {
        this.setState({
          showConfirmModal: false,
          confirmMessage: null,
          confirmTitle: null,
          wagxEngineModel: null
        });
      }
    });
  }

  onOpenViewClick = (props, actionProps) => {
    let objectId = null;
    let loadedObject = null;
    const objectIdField = actionProps.objectIdField;
    const viewStateApplyFilters = this.props.viewState != null && Array.isArray(this.props.viewState.applyFilters)
      ? this.props.viewState.applyFilters
      : null;

    if (props != null && props.object != null) {
      const clickedObject = { ...props.object };
      if (actionProps.loadedObject != null) {
        loadedObject = lodash.cloneDeep(actionProps.loadedObject);
        for (const key in loadedObject) {
          if (actionProps.parseFromObject === true && typeof loadedObject[key] === "string") {
            loadedObject[key] = parseString(loadedObject[key], clickedObject);
          } else if (actionProps.parseFromObject !== true) {
            loadedObject[key] = lodash.get(clickedObject, key, null);
          }
        }
      }

      if (objectIdField != null) {
        if (Array.isArray(objectIdField)) {
          objectIdField.forEach(idField => {
            objectId += lodash.get(clickedObject, idField, null);
            objectId += "_";
          });
        } else {
          objectId = lodash.get(clickedObject, objectIdField, null);
        }
      }
    } else {
      loadedObject = lodash.cloneDeep(actionProps.loadedObject);
      if (viewStateApplyFilters != null) {
        //filtri che arrivano dai link
        if (objectIdField != null) {
          viewStateApplyFilters.forEach(element => {
            if (loadedObject != null && loadedObject.hasOwnProperty(element.param)) {
              loadedObject[element.param] = element.value;
            }
            if (Array.isArray(objectIdField)) {
              objectIdField.forEach(idField => {
                if (element.param === idField) {
                  objectId += element.value;
                  objectId += "_";
                }
              });
            } else {
              if (element.param === objectIdField) {
                objectId = element.value;
              }
            }
          });
        }
      }
    }

    //Rimuovo "_" a fine di objectId dovuto ai cicli soprastanti per generare l'ID composto
    if (objectId != null && typeof objectId === "string" && objectId.endsWith("_")) {
      objectId = objectId.slice(0, -1);
    }

    const updatedState = Object.assign({}, this.context.location != null ? this.context.location.state : this.props.location.state);
    if (actionProps.pushToState === true) {
      let object = {};
      if (props != null) {
        object = { ...props.object }
      }

      const applyFilters = computeApplyFiltersParam(actionProps, object, objectId, viewStateApplyFilters);

      updatedState["SearchResultModal"] = {
        applyFilters: applyFilters,
        destinationTitle: getLocalizedProperty(actionProps, "destinationTitle", actionProps.destinationTitle, object, actionProps.formatterFieldMap),
        page: 0,
        filters: []
      }
    } else {
      if (updatedState["SearchResultModal"]) {
        updatedState["SearchResultModal"].destinationTitle = null;
      }
    }

    this.props.history.push(
      this.context.location != null ? this.context.location.pathname : this.props.location.pathname,
      updatedState
    );

    let viewIdToOpenInDialog = actionProps.viewIdToOpenInDialog;
    if (typeof viewIdToOpenInDialog === "string") {
      viewIdToOpenInDialog = parseString(actionProps.viewIdToOpenInDialog, props.object);
      if (!isNaN(viewIdToOpenInDialog) && viewIdToOpenInDialog !== "") {
        viewIdToOpenInDialog = Number(viewIdToOpenInDialog);
      } else {
        manageError("DataTable - configuration error", null, { viewIdToOpenInDialog: viewIdToOpenInDialog, viewId: this.props.id });
      }
    }

    this.props.showSearchModal(viewIdToOpenInDialog, actionProps.modalStyle != null ? actionProps.modalStyle : null, objectId, loadedObject);
  }

  executeFunctionHandler = (functionName, showMessages = false, returnsFile = false, action) => {
    if (action.showLoadingModal) {
      this.props.showLongOperationWaitingModal();
    }
    return new Promise((resolve, reject) => {
      axios.post("views/" + this.props.id + "/" + functionName + "/" + this.props.objectId)
        .then(response => {
          if (action.showLoadingModal) {
            this.props.hideLongOperationWaitingModal();
          }
          if (response.data.success) {
            if (action.updatePageOnSuccess != null) {
              const pageState = Object.assign({}, this.props.page);
              action.updatePageOnSuccess.forEach(state => {

                if (pageState[state.pageViewStateAttribute] == null) {
                  pageState[state.pageViewStateAttribute] = {};
                }
                pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
              });
              this.props.setPageState(pageState);
            }
            if (action.updateDataTableOnSuccess != null) {
              let dataTableState = {};
              action.updateDataTableOnSuccess.forEach(state => {
                let viewId = null;
                if (state.getViewIdFromUrl === true) {
                  const path = this.props.location.pathname;
                  viewId = path.substr((path.lastIndexOf("/") + 1), path.length);
                } else {
                  viewId = state.viewId
                }
                if (dataTableState[viewId] == null) {
                  dataTableState[viewId] = {};
                }
                dataTableState[viewId] = state.viewState
              });
              this.props.setDataTableState(dataTableState);
            }
          if (showMessages) {
            showMessageModal(response.data.success, null, response.data.responseMessages, true)
          }
          } else {
            if (showMessages) {
              showMessageModal(response.data.success, null, response.data.responseMessages, true)
            }
          }

          this.loadData();
          resolve(response);
        }).catch(exception => {
	        if (action.showLoadingModal) {
            this.props.hideLongOperationWaitingModal();
          }
          if (!axiosLib.isCancel(exception)) {
            manageError("GenericForm", null, exception,
              showMessages ? intl.get("DataTable.error.actionFailed.caption").d("Errore eseguendo un azione") : null,
              showMessages ? manageResponseDataMessageList(false, exception.response.data.responseMessages).messageList : null
            );
          }
          reject(exception);
        });
    });
  }

  onFileDownloadClick = (viewId, fieldValue, object, pageViewToReload, nativeDownload) => {
    const fileDownloadUrl = "/views/" + viewId + "/download/" + fieldValue;
    if (nativeDownload) {
      const link = document.createElement('a');
      link.href = window.APP_CONFIG.baseURL + fileDownloadUrl;
      link.target = "_blank";
      link.download = "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
    this.setState({ downloadInProgress: true });
    axios.get(fileDownloadUrl, {
      responseType: "blob"
    }).then((response) => {
      fileDownload(response.data, contentDisposition.parse(response.headers["content-disposition"]).parameters["filename"]);
      if (pageViewToReload != null) {
        let pageState = {};
        pageViewToReload.forEach(state => {
          if (pageState[state.pageViewStateAttribute] == null) {
            pageState[state.pageViewStateAttribute] = {};
          }
          pageState[state.pageViewStateAttribute][parseString(state.viewStateAttribute, object)] = state.viewState;
        });
        this.props.setPageState(pageState);
      }
      this.setState({ downloadInProgress: false });
    }).catch(exception => {
      if (!axiosLib.isCancel(exception)) {
        manageError("DataTable", null, exception,
          intl.get("DataTable.error.downloadError.caption").d("Si è verificato un errore"),
          intl.get("DataTable.error.downloadError.text").d("Si è verificato un errore in download.")
        );
      }
      this.setState({ downloadInProgress: false });
    });
  }
  }

  loadData = (functionName = null, showSpinner = true) => {
    if (this.props.loadDataHandler) {
      this.props.loadDataHandler();
    } else {
      if ((this.props.persistFilter && this.state.persistedFilterLoaded) || !this.props.persistFilter) {
        if (this.searchMode === DataTableTypes.SEARCH && (this.props.viewState == null || this.props.viewState.filters == null || this.props.viewState.filters.length === 0)) {
          this.setState({
            tableData: [],
            page: {
              number: 1,
              size: this.state.page.size
            },
            dataLoaded: true,
            loading: false,
            items: [],
            groups: [],
            lastRefreshDate: null,
            orderBy: ''
          });
        } else {
          if (this.requestCancelToken != null) {
            this.requestCancelToken.cancel();
            this.requestCancelToken = null;
          }

          let restBaseUrl = this.props.restBaseUrl;
          if (functionName !== null) {
            restBaseUrl = "views/" + this.props.id + "/" + functionName;
          }

          const datasetUrl = this.generateUrl(restBaseUrl);

          let startTime = moment();
          this.requestCancelToken = axiosLib.CancelToken.source();
          axios.get(datasetUrl, { cancelToken: this.requestCancelToken.token }).then(response => {
            if (!this._isMounted) {
              return;
            }
            let tableData = response.data.content;
            let page = {
              number: response.data.page.number - 1,
              size: response.data.page.size,
              totalElements: response.data.page.totalElements,
              totalNoFilterElements: response.data.page.totalNoFilterElements,
              totalPages: response.data.page.totalPages
            }
            if (response.data.success === undefined || response.data.success) {
              if (this.props.filterDatasetHandler) {
                tableData = this.props.filterDatasetHandler(tableData);
              }

              const items = this.createItemsArray([...tableData]);
              const groups = this.props.groupedList ? this.createGroupsArray([...tableData]) : null;

              this._selectionChangeDisabled = true;
              if (this.preference != null && !Array.isArray(this.preference.itemsField)) {
                const selectedIndexes = [];
                if (this.selectedItemsPreference.length > 0) {
                  this.selectedItemsPreference.forEach(selectedItem => {
                    // eslint-disable-next-line eqeqeq
                    const selectedIndex = items.findIndex((item) => item[Object.keys(item)[0]].props.object[this.preference.itemsField] == selectedItem);
                    if (selectedIndex > -1) {
                      selectedIndexes.push(selectedIndex);
                    }
                  });
                }

                this._selection.getSelectedIndices().forEach(selectedIndex => {
                  const selectedItem = this._selection.getItems()[selectedIndex];
                  const itemIndex = items.findIndex(item => item[Object.keys(item)[0]].props.object[this.preference.itemsField] === selectedItem[Object.keys(selectedItem)[0]].props.object[this.preference.itemsField]);
                  if (itemIndex === -1) {
                    this.discardedSelectedItemsPreference.push(selectedItem[Object.keys(selectedItem)[0]].props.object[this.preference.itemsField]);
                  }
                });
                this._selection.setItems(items, true);
                selectedIndexes.forEach(selectedIndex => {
                  this._selection.setIndexSelected(selectedIndex, true, false);
                });
              } else if (this.props.selectionEnabled) {
                this._selection.setItems(this.props.groupedList ? groups : items, true);
              }
              this._selectionChangeDisabled = false;

              let endTime = moment();
              this.setState({
                tableData: tableData,
                page: page,
                dataLoaded: true,
                loading: false,
                items: items,
                lastRefreshDate: new Date(),
                groups: groups,
                loadTime: endTime.diff(startTime)
              }, () => {
                this.setDefaultSelection();
              });
              if (this.props.autoRefreshTime) {
                if (this.autoRefreshHandler) {
                  clearTimeout(this.autoRefreshHandler);
                  this.autoRefreshHandler = null;
                }
                this.autoRefreshHandler = setTimeout(() => this.loadData(null, false), this.props.autoRefreshTime * 1000);
              }
              this.requestCancelToken = null;
            } else {
              showMessageModal(response.data.success, null, response.data.responseMessages, true);

              let endTime = moment();
              this.setState({
                tableData: tableData,
                page: page,
                dataLoaded: true,
                loading: false,
                items: [],
                lastRefreshDate: new Date(),
                groups: [],
                loadTime: endTime.diff(startTime)
              });
            }
          }).catch(exception => {
            if (!axiosLib.isCancel(exception)) {
              manageError("DataTable", null, exception);

              if (this._isMounted) {
                const nextStateObject = {
                  dataLoaded: true,
                  loading: false
                };
                nextStateObject.items = [];
                nextStateObject.groups = [];
                nextStateObject.page = {
                  number: 0,
                  size: 0,
                  totalPages: 0,
                  totalElements: 0
                };

                this.setState(nextStateObject);

                if (this.props.autoRefreshTime) {
                  if (this.autoRefreshHandler) {
                    clearTimeout(this.autoRefreshHandler);
                    this.autoRefreshHandler = null;
                  }
                  this.autoRefreshHandler = setTimeout(() => this.loadData(null, false), this.props.autoRefreshTime * 1000);
                }
              } else {
                clearTimeout(this.autoRefreshHandler);
              }
              this.requestCancelToken = null;
            }
          });
          this.setState({ loading: true, dataLoaded: !showSpinner });
        }
      }
    }
  }

  linkClickHandler = (event, item) => {
    const props = item.action;
    const applyFilters = this.props.viewState ? this.props.viewState.applyFilters : [];
    let path = parseStringFromApplyFilters(props.path, applyFilters, false);
    path = parseString(path, this.props.loadedObject);
    if (props.sendRedirectUrl != null) {
      const redirectUrl = props.sendRedirectUrl.pattern.replace("{wagx.baseURL}", config.baseURL);
      path += path.indexOf("?") === -1 ? "?" : ""
      path += "&" + props.sendRedirectUrl.param + "=" + encodeURI(redirectUrl)
    }
    clickLinkHandler(path, props, this);
  }

  exportDataHandler = (event, size, type = "xlsx") => {
    let datasetUrl = this.generateUrl(this.props.restBaseUrl);
    datasetUrl = datasetUrl.replace("views/", "export/");
    let goAhead = true;
    if (size != null) {
      let maxRowsToExport = 10000; // Default;
      if (this.props.maxRowsToExport && (+(this.props.maxRowsToExport) > 0)) {
        maxRowsToExport = +(this.props.maxRowsToExport);
      }
      if (+(size) > maxRowsToExport) {
        goAhead = false;
        const message = buildMessage(MessageSeverityEnum.INFO, "DataTable.error.exportTooMuchRows.text", { maxRowsToExport: maxRowsToExport });
        showMessageModal(false, null, message, false);
      } else {
        datasetUrl = datasetUrl.replace("size=" + this.state.page.size, "size=" + size);
      }
    }
    datasetUrl += "&type=" + type;
    if (goAhead) {
      axios.get(datasetUrl, {
        responseType: "blob"
      }).then((response) => {
        fileDownload(response.data, response.headers["filename"]);
        this.setState({ exportInProgress: false });
      }).catch((exception) => {
        this.setState({ exportInProgress: false });
        if (!axiosLib.isCancel(exception)) {
          manageError("DataTable", null, exception,
            intl.get("DataTable.error.exportingError.caption").d("Si è verificato un errore"),
            intl.get("DataTable.error.exportingError.text").d("Si è verificato un errore in esportazione."),
          );
        }
      });
      this.setState({ exportInProgress: true });
    }
  }

  refreshHandler = () => {
    this.setState({ dataLoaded: false });
  }

  generateUrl = (datasetUrl) => {
    let queryString = '';
    if (this.props.pagination) {
      let currentPage = 0;
      if (this.props.viewState && this.props.viewState.page) {
        currentPage = this.props.viewState.page;
      }
      queryString += '&page=' + currentPage;
      queryString += '&size=' + this.state.page.size;
    }
    if (this.state.orderBy) {
      queryString += '&sort=' + this.state.orderBy + "," + this.state.order;
    }
    if (this.props.viewState) {
      for (const key in this.props.viewState.filters) {
        if (this.props.viewState.filters.hasOwnProperty(key)) {
          const element = this.props.viewState.filters[key];
          if (element.value != null && element.value !== "") {
            let value;
            let alreadyInQueryParam = false;
            switch (element.type) {
              case "Date":
              case "date":
                if (Array.isArray(element.value)) {
                  for (let i = 0; i < element.value.length; i++) {
                    if (element.value[i]) {
                      element.value[i] = formatters["momentDateToStringFormatter"](element.value[i]);
                    }
                  }
                } else {
                  element.value = formatters["momentDateToStringFormatter"](element.value);
                }
                value = this.generateArrayFilters(element);
                break;
              case DATE_RANGE:
                value = new DateRangeValue(element.value);
                value.fromDate = value.fromDate != null ? formatters["momentDateToStringFormatter"](value.fromDate) : null;
                value.toDate = value.toDate != null ? formatters["momentDateToStringFormatter"](value.toDate) : null;
                value = value.toString();
                break;
              case CURRENCY_RANGE:
                value = new CurrencyRangeValue(element.value);
                value = value.toString();
                break;
              case "Select":
              case "select":
                if (Array.isArray(element.value)) {
                  for (let i = 0; i < element.value.length; i++) {
                    let val = element.value[i];
                    queryString += '&' + key + '=' + encodeURIComponent(val);
                  }
                  alreadyInQueryParam = true;
                } else {
                  value = "EQ(" + element.value + ")";
                }
                break;
              case "int":
              case "Int":
              case "float":
                value = this.generateArrayFilters(element);
                break;
              default:
                if (Array.isArray(element.value)) {
                  for (let i = 0; i < element.value.length; i++) {
                    let val = element.value[i];
                    queryString += '&' + key + '=' + encodeURI(val);
                  }
                  alreadyInQueryParam = true;
                } else {
                  value = element.value;
                }
            }
            if (!alreadyInQueryParam) {
              queryString += '&' + key + '=' + encodeURIComponent(value);
            }
          }
        }
      }

      if (this.props.viewState.applyFilters) {
        //filtri che arrivano dai link
        this.props.viewState.applyFilters.forEach(element => {
          if (element.value != null && element.value !== "") {
            let value = element.value;
            if (element.type === "Date" || element.type === "date") {
              value = formatters["momentDateToStringFormatter"](element.value);
            }
            queryString += '&' + element.param + '=' + encodeURIComponent(value);
          }
        });
      }
    }
    if (this.props.queryString != null) {
      queryString += this.props.queryString;
    }
    if (this.props.filterObject != null) {
      for (let key in this.props.filterObject) {
        if (!queryString.includes(key)) {
          queryString += "&" + key + "=" + this.props.filterObject[key].value;
        }
      }
    }

    if (datasetUrl.indexOf('?') === -1) {
      datasetUrl += "?";
    }
    datasetUrl += queryString;
    return datasetUrl;
  }

  generateArrayFilters = (element) => {
    let value;
    switch (element.filterType) {
      case "BW": //BETWEEN
        if (Array.isArray(element.value)) {
          if (element.value[0] === null || element.value[0] === undefined || element.value[0] === "") {
            value = "LT(" + element.value[1] + ")";
          } else if (element.value[1] === null || element.value[1] === undefined || element.value[1] === "") {
            value = "GT(" + element.value[0] + ")";
          } else {
            value = "BW(" + element.value[0] + ";" + element.value[1] + ")";
          }
        } else {
          value = "EQ(" + element.value + ")";
        }
        break;
      case "IN":
        if (Array.isArray(element.value)) {
          let i;
          value = "IN(";
          for (i = 0; i < element.value.length; i++) {
            if (element.value[i] != null) {
              value += element.value[i] + ";";
            }
          }
          value = value.substring(0, value.length - 1) + ")";
        } else {
          value = "EQ(" + element.value + ")";
        }
        break;
      case "EQ":
        if (Array.isArray(element.value)) {
          value = "EQ(" + element.value[0] + ")";
        } else {
          value = "EQ(" + element.value + ")";
        }
        break;
      case "NEQ":
        if (Array.isArray(element.value)) {
          value = "NEQ(" + element.value[0] + ")";
        } else {
          value = "NEQ(" + element.value + ")";
        }
        break;
      case "GT": //GREATER THEN EQUALS (>=)
        if (Array.isArray(element.value)) {
          value = "GT(" + element.value[0] + ")";
        } else {
          value = "GT(" + element.value + ")";
        }
        break;
      case "LT": //LESS THEN EQUALS (<=)
        if (Array.isArray(element.value)) {
          value = "LT(" + element.value[0] + ")";
        } else {
          value = "LT(" + element.value + ")";
        }
        break;
      default:
        if (Array.isArray(element.value)) {
          if (element.value.length === 1) {
            value = "EQ(" + element.value[0] + ")";
          } else if (element.value.length > 2) {
            value = "IN(" + element.value[0];
            for (let i = 1; i < element.value.length; i++) {
              if (element.value[i] != null) {
                value += ";" + element.value[i];
              }
            }
            value += ")";
          } else if (element.value.length === 2) {
            if (element.value[0] === null || element.value[0] === undefined || element.value[0] === "") {
              value = "LT(" + element.value[1] + ")";
            } else if (element.value[1] === null || element.value[1] === undefined || element.value[1] === "") {
              value = "GT(" + element.value[0] + ")";
            } else {
              value = "BW(" + element.value[0] + ";" + element.value[1] + ")";
            }
          }
        } else {
          value = "EQ(" + element.value + ")";
        }
        break;
    }
    return value;
  }

  createColumnsArray(props, state) {
    let columns = [];
    let headerClassName = null;
    if (props.style) {
      headerClassName = props.style.headerRowClassName;
    }

    let columnsArray = props.groupedList ? props.groupColumns : props.columns;
    columnsArray = JSON.parse(JSON.stringify(columnsArray));
    columnsArray.forEach((column, index) => {
      if (column.showColumnIf != null) {
        const funcBody = computeFunctionBody(column.showColumnIf);
        // eslint-disable-next-line 
        const func = new Function("object", "profiles", funcBody);
        const result = func(this.props.loadedObject == null ? {} : this.props.loadedObject, this.props.auth.appState.profiles);

        if (result === false) {
          return;
        }
      }
      // Forzatura data dal MultiSelector che nel suo lato destro non è ancora disponibile gestione ordinamento e filtro
      if (this.props.hideFilterAndSort === true) {
        column.sortable = false;
        column.filterable = false;
        column.filterList = undefined;
        column.filterTitle = undefined;
      }
      // Fine forzatura
      let columnHeader = getLocalizedProperty(column, "header", column.header);
      if (column.fieldType !== "hidden") {

        const fieldFilter = state.filterObject[column.filterList && column.filterList.field ? column.filterList.field : column.field];
        let isFiltered = false;
        if (fieldFilter != null) {
          if (Array.isArray(fieldFilter.value)) {
            isFiltered = fieldFilter.value.length !== 0;
          } else {
            isFiltered = fieldFilter.value !== "";
          }
        }
        const stateColumn = {
          key: column.field,
          columnActionsMode: column.sortable || column.filterable ? ColumnActionsMode.hasDropdown : ColumnActionsMode.disabled,
          name: columnHeader,
          columnHeader: columnHeader,
          headerClassName: column.headerClassName ? column.headerClassName : headerClassName,
          fieldName: column.field,
          iconName: column.iconName,
          iconClassName: column.iconClassName,
          isResizable: column.resizable,
          isFiltered: isFiltered,
          isSorted: column.field === state.orderBy,
          isSortedDescending: column.field === state.orderBy && state.order === "desc",
          hasDropdown: column.sortable || column.filterable,
          isMultiline: column.multiline ? column.multiline : false,
          minWidth: column.fieldType === "detail" ? 16 : column.minWidth ? column.minWidth : column.width,
          maxWidth: column.fieldType === "detail" ? 16 : column.maxWidth ? column.maxWidth : column.width,
          data: 'string',
          onColumnClick: (ev) => this.onColumnClickHandler(ev, column, state),
          onRender: (item) => {
            const cellClassName = (column.cellClassName != null && item[column.field + index] !== undefined) ? parseString(column.cellClassName, item[column.field + index].props.object) : "";
            return (<div className={cellClassName + " dataTableTdMiddle32"}>{item[column.field + index]}</div>);
          }
        }

        if (column.sortable || column.filterable) {
          stateColumn.iconName = column.iconName;
          stateColumn.iconClassName = column.iconClassName;
        }

        columns.push(stateColumn);
      }
    });

    const defaultColumns = [];
    columns = [...columns, ...defaultColumns];

    return columns;
  }


  getContextualMenuProps = (ev, column, state) => {
    let columnItems = [];
    columnItems.push(
      {
        key: "Default",
        name: "",
        canCheck: false,
        disabled: true,
        className: "ContextualMenuDefaultColumnItem"
      });

    if (column.sortable) {
      columnItems.push(
        {
          key: "orderAsc",
          name: intl.get("DataTable.ascendingOrder").d("Ordina Crescente"),
          canCheck: true,
          checked: (column.field === state.orderBy && state.order === "asc"),
          iconProps: {
            iconName: "SortUp"
          },
          onClick: () => this.onColumnSortHandler(column.field, "asc")
        });

      columnItems.push(
        {
          key: "orderDesc",
          name: intl.get("DataTable.descendingOrder").d("Ordina Decrescente"),
          canCheck: true,
          checked: (column.field === state.orderBy && state.order === "desc"),
          iconProps: {
            iconName: "SortDown"
          },
          onClick: () => this.onColumnSortHandler(column.field, "desc")
        });

      columnItems.push(
        {
          key: "orderRemove",
          name: intl.get("DataTable.removeOrder").d("Rimuovi Ordinamento"),
          canCheck: false,
          disabled: (column.field !== state.orderBy),
          onClick: () => this.onColumnSortHandler()
        });
    }

    let title = getLocalizedProperty(column, "filterTitle");
    if (title == null || title === "") {
      title = getLocalizedProperty(column, "header");
    }
    if (title == null || title === "") {
      title = getLocalizedProperty(column, "title");
    }
    if (column.filterable) {
      title = (
        <React.Fragment>
          {title}
          <span className="column-clear-filter-button">
            <IconButton
              iconProps={{ iconName: 'ClearFilter', color: "white" }}
              onClick={() => this.onClearFiltersHandler(column.filterList ? column.filterList.field : column.field)}
              title={intl.get("DataTable.removeFilter").d("Rimuovi Filtro")}
              ariaLabel={intl.get("DataTable.removeFilter").d("Rimuovi Filtro")}
            />
          </span>
        </React.Fragment>
      );
    }

    return {
      items: columnItems,
      target: ev.currentTarget,
      title: title,
      directionalHint: DirectionalHint.bottomLeftEdge,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: this.onContextualMenuDismissedHandler
    };
  }

  onLiveFilterChange = (ev, value) => {
    this.setState({
      liveFilterString: value
    }, () => {
      if (this.liveFilterTimeout != null) {
        clearTimeout(this.liveFilterTimeout);
      }
      this.liveFilterTimeout = setTimeout(() => {
        this.setState({ liveFilterStringConsolidated: this.state.liveFilterString });
      }, 500);
    });
  }

  onContextualMenuDismissedHandler = () => {
    this.setState({
      contextualMenuProps: undefined
    });
  }

  onColumnClickHandler = (ev, column, state) => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      this.setState({
        contextualMenuProps: this.getContextualMenuProps(ev, column, state),
        selectedColumn: column
      });
    }
  };

  handleOnDateBlur = e => {
    const { filterObject, selectedColumn } = this.state;

    let filterValue = filterObject[selectedColumn.field] != null ? filterObject[selectedColumn.field].value : '';

    let shouldSubmit = false;

    if (Array.isArray(filterValue)) {
      if (filterValue[0] !== null && filterValue[1] !== null && e.relatedTarget == null) {
        shouldSubmit = true;
      } else if (filterValue[0] !== null || filterValue[1] !== null) {
        shouldSubmit = e.relatedTarget == null;
      }
    }

    this.onColumnFilterChangedHandler(filterValue, selectedColumn.field, selectedColumn.fieldType, shouldSubmit);
  }

  renderMenuList(menuListProps, defaultRender) {
    const column = this.state.selectedColumn;
    let filter = null;
    const menuListToRender = Object.assign({}, menuListProps);
    if (column.filterable) {
      if (!menuListProps.items[0].key) menuListToRender.items.splice(0, 1);
      let filterValue = this.state.filterObject[column.field] != null ? this.state.filterObject[column.field].value : "";
      switch (column.fieldType) {
        case "date":
          if (column.rangeFilter) {
            let filterValueFrom = null;
            let filterValueTo = null;
            if (Array.isArray(filterValue)) {
              filterValueFrom = filterValue[0];
              filterValueTo = filterValue[1];
              filterValue = [filterValueFrom, filterValueTo];
              if (filterValueFrom && filterValueTo) {
                filterValue = filterValue.sort();
              }
            } else {
              filterValue = [filterValueFrom, filterValueTo];
            }

            filter = this.rangeStrings.map((item, index) => {
              const maxDate = (index === 0 && filterValue[1] != null) ? new Date(filterValue[1]) : null
              const minDate = (index === 1 && filterValue[0] != null) ? new Date(filterValue[0]) : null
              return (
                <DatePicker
                  {...column}
                  onChange={(event, field, date) => {
                    filterValue[index] = date;
                    let shouldSubmit = filterValue[0] !== null && filterValue[1] !== null;
                    this.onColumnFilterChangedHandler(filterValue, field, column.fieldType, shouldSubmit);
                  }}
                  value={formatters["parseDateFromMillisec"](filterValue[index])}
                  allowTextInput={false}
                  required={false}
                  placeholder={item}
                  key={item}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              );
            });
          } else (
            filter = <DatePicker
              {...column}
              onChange={(event, field, date) => this.onColumnFilterChangedHandler(date, field, column.fieldType, true)}
              value={formatters["parseDateFromMillisec"](filterValue)}
              allowTextInput={false}
            />
          )
          break;
        default:
          if (column.filterList) {
            const filterListValue = this.state.filterObject[column.filterList.field] != null ? this.state.filterObject[column.filterList.field].value : "";

            let dependsOnFields = null;
            if (column.filterList.dependsOnField != null) {
              if (this.props.viewState != null && this.props.viewState.applyFilters != null && Array.isArray(this.props.viewState.applyFilters)) {
                for (const dependsOnField of column.filterList.dependsOnField) {
                  const applyFilter = this.props.viewState.applyFilters.find(filter => filter.param === dependsOnField.param);

                  if (applyFilter != null) {
                    if (dependsOnFields == null) {
                      dependsOnFields = [];
                    }

                    dependsOnFields.push({
                      param: dependsOnField.param,
                      value: applyFilter.value
                    });
                  }
                }
              }
            }

            filter = <SelectField
              invokeOnChangeOnLoad={false}
              notifyOnClose={column.filterList.multiSelect}
              multiSelect={column.filterList.multiSelect}
              lovId={column.filterList.lovId}
              lovValue={column.filterList.lovValue}
              placeholder={getLocalizedProperty(column.filterList, 'placeholder')}
              label={getLocalizedProperty(column.filterList, 'label')}
              onChange={(event, field, selection) => this.onColumnFilterChangedHandler(selection, field, column.filterList.fieldType, true, column.filterType)}
              value={formatters[column.filterList.formatter](filterListValue)}
              name={column.filterList.field}
              dependsOnFields={dependsOnFields}
            />;
          } else {
            if (column.rangeFilter) {
              let filterValueFrom = null;
              let filterValueTo = null;
              if (Array.isArray(filterValue)) {
                filterValueFrom = filterValue[0];
                filterValueTo = filterValue[1];
              } else {
                filterValue = [filterValueFrom, filterValueTo];
              }

              filter = <div className="SearchBoxRangeFilterContainer">
                <SearchBox
                  key="NumberRange1"
                  iconProps={{ iconName: "Filter" }}
                  placeholder={getLocalizedProperty(column, "header")}
                  name={column.field + "1"}
                  value={formatters["defaultStringFormatter"](filterValue[0])}
                  onChange={(newValue) => {
                    filterValue[0] = newValue;
                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, false, column.filterType);
                  }}
                  onBlur={(event) => {
                    if (event.target.localName !== "input") {
                      return;
                    }

                    filterValue[0] = event.target.value;

                    if (filterValue[0] != null && filterValue[0] !== 0) {
                      if (filterValue[1] == null || filterValue[1] === 0) {
                        filterValue[1] = filterValue[0];
                      } else if (filterValue[0] > filterValue[1]) {
                        filterValue[1] = filterValue[0];
                      }
                    }

                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, false, column.filterType);
                  }}
                  onSearch={(newValue) => {
                    filterValue[0] = newValue;
                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, true, column.filterType)
                  }}
                  clearButtonProps={{
                    style: { display: formatters["defaultStringFormatter"](filterValue[0]) !== "" ? "block" : "none" },
                    onClick: () => formatters["defaultStringFormatter"](filterValue[0]) !== "" ? this.onClearFiltersHandler(column.field) : ""
                  }}
                />
                <SearchBox
                  key="NumberRange2"
                  iconProps={{ iconName: "Filter" }}
                  placeholder={getLocalizedProperty(column, "header")}
                  name={column.field + "2"}
                  value={formatters["defaultStringFormatter"](filterValue[1])}
                  onChange={(newValue) => {
                    filterValue[1] = newValue;
                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, false, column.filterType);
                  }}
                  onBlur={(event) => {
                    if (event.target.localName !== "input") {
                      return;
                    }

                    filterValue[1] = event.target.value;

                    if (filterValue[1] != null && filterValue[1] !== 0) {
                      if (filterValue[0] == null || filterValue[0] === 0) {
                        filterValue[0] = filterValue[1];
                      } else if (filterValue[1] < filterValue[0]) {
                        filterValue[0] = filterValue[1];
                      }
                    }

                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, false, column.filterType);
                  }}
                  onSearch={(newValue) => {
                    filterValue[1] = newValue;
                    this.onColumnFilterChangedHandler(filterValue, column.field, column.fieldType, true, column.filterType)
                  }}
                  clearButtonProps={{
                    style: { display: formatters["defaultStringFormatter"](filterValue[1]) !== "" ? "block" : "none" },
                    onClick: () => formatters["defaultStringFormatter"](filterValue[1]) !== "" ? this.onClearFiltersHandler(column.field) : ""
                  }}
                />
              </div>;
            } else {
              filter = <SearchBox
                placeholder={getLocalizedProperty(column, "header")}
                onChange={(newValue) => this.onColumnFilterChangedHandler(newValue, column.field, column.fieldType, false, column.filterType)}
                name={column.field}
                onSearch={(newValue) => this.onColumnFilterChangedHandler(newValue, column.field, column.fieldType, true, column.filterType)}
                value={formatters["defaultStringFormatter"](filterValue)}
                clearButtonProps={{
                  style: { display: formatters["defaultStringFormatter"](filterValue) !== "" ? "block" : "none" },
                  onClick: () => formatters["defaultStringFormatter"](filterValue) !== "" ? this.onClearFiltersHandler(column.field) : ""
                }}
                iconProps={{ iconName: "Filter" }}
              />;
            }

          }
          break;
      }
    }

    return (
      <div>
        {filter}
        {defaultRender(menuListToRender)}
      </div>
    );
  }

  onColumnFilterChangedHandler = (value, field, type, submit, filterType = null) => {
    const updateFilterObject = Object.assign({}, this.state.filterObject);
    updateFilterObject[field] = { value: value, type: type, filterType: filterType };
    if (this.props.onColumnFilterChangedHandler) {
      if (value != null && submit) {
        this.props.onColumnFilterChangedHandler(updateFilterObject, this.props.multiSelectorSideKey);
        this.onContextualMenuDismissedHandler();
      } else {
        this.setState({ filterObject: updateFilterObject });
      }
    } else {
      if (value != null && submit) {
        this.onColumnFilterSearchHandler(updateFilterObject);
      } else {
        this.setState({ filterObject: updateFilterObject });
      }
    }
  }

  onColumnFilterSearchHandler = (updateFilterObject) => {
    if (updateFilterObject) {
      this.props.filterListHandler(updateFilterObject);
      this.onContextualMenuDismissedHandler();
    }
  }

  onClearFiltersHandler = (field) => {
    this.setState({
      contextualMenuProps: undefined
    }, () => {
      const filters = Object.assign({}, this.state.filterObject)
      for (const key in this.state.filterObject) {
        if (this.state.filterObject.hasOwnProperty(key)) {
          if (field === key) {
            delete filters[key];
          }
        }
      }
      if (this.props.onColumnFilterChangedHandler) {
        this.props.onColumnFilterChangedHandler(filters, this.props.multiSelectorSideKey);
      } else {
        this.setState({ filterObject: filters });
        this.props.filterListHandler(filters);
      }
    });
  }

  onClearAllFiltersHandler = () => {
    this.setState({ filterObject: {} });
    this.props.filterListHandler([]);
  }

  createItemsArray(tableData) {
    const items = tableData.map((rowData, rowDataIndex) => {
      let item = {};
      this.props.columns.forEach((element, index) => {
        if (element.fieldType !== "hidden") {
          let filters = [];
          if (this.props.filters) {
            filters = [...this.props.filters];
          }

          if (element.filters != null) {
            filters = [...element.filters, ...filters];
          }

          item[element.field + index] =
            (<DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              viewId={this.props.id}
              key={index}
              object={rowData}
              showDetailHandler={this.props.showDetailHandler}
              value={rowData[element.field]}
              width={element.width}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, rowDataIndex)}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              columnStructure={element}
              {...element}
              filters={[...filters]}
              preloadedLovs={this.props.preloadedLovs}
            />
            );
        }
      });
      return item;
    });
    return items;
  }

  createGroupItem(rowData, rowDataIndex, groupRowsObject) {
    let item = {};
    this.props.groupColumns.forEach((element, index) => {
      if (element.fieldType !== "hidden") {
        let filters = [];
        if (this.props.filters) {
          filters = [...this.props.filters];
        }

        if (element.filters != null) {
          filters = [...element.filters, ...filters];
        }

        item[element.field + index] =
          (
            <DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              viewId={this.props.id}
              key={index}
              object={rowData}
              groupRowsObject={groupRowsObject}
              value={rowData[element.field]}
              showDetailHandler={this.props.showDetailHandler}
              onIconButtonClick={null}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, rowDataIndex)}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              columnStructure={element}
              {...element}
              filters={[...filters]}
            />
          );
      }
    });
    return item;
  }
  onRenderGroupHeader = (props) => {
    let groupRowClassName = null;
    const rowIndexModule = props.groupIndex % 2;
    if (this.props.style != null) {
      groupRowClassName = this.props.style.groupRowClassName + rowIndexModule;
    }

    this.props.groupColumns.forEach((element, index) => {

      let firstGroupRow = null
      if (this.state.items && props.group) {
        const temp1 = this.state.items[props.group.startIndex];
        if (temp1) {
          firstGroupRow = { ...temp1[Object.keys(temp1)[0]].props.object }
        }
      }

      if (element.field === "expandButton") {
        props.group.item[element.field + index] =
          (
            <DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              key={index + props.group.isCollapsed}
              showDetailHandler={this.props.showDetailHandler}
              onIconButtonClick={() => props.onToggleCollapse(props.group)}
              iconName={props.group.isCollapsed ? "ChevronRightSmall" : "ChevronDownSmall"}
              style={{ fontSize: "12px" }}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, index)}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              columnStructure={element}
              {...element}
              className={element.className + " expandButton"}
              object={firstGroupRow}
            />
          );
      } else if (element.field === "itemsCount") {
        props.group.item[element.field + index] =
          (
            <DataTableTd
              {...this.props}
              title={undefined}
              titleLocaleKey={undefined}
              titleParseBeforeTranslate={undefined}
              key={index}
              showDetailHandler={this.props.showDetailHandler}
              onIconButtonClick={() => props.onToggleCollapse(props.group)}
              style={{ fontSize: "12px" }}
              downloadFileHandler={this.onFileDownloadClick}
              openViewHandler={this.onOpenViewClick}
              sendData={this.sendData}
              sendDataConfirmDialog={this.sendDataConfirmDialog}
              sendDataPreActionDialog={this.sendDataPreActionDialog}
              prepareEngineWorkflow={this.prepareEngineWorkflow}
              onIconSwitchChange={this.onIconSwitchChange}
              loadData={this.loadData}
              onChange={(event, field, value, errors) => this.props.onChange(event, field, value, errors, this.props.dataField, index)}
              columnStructure={element}
              {...element}
              applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
              text={"( " + props.group.count + " )"}
              object={firstGroupRow}
            />
          );
      } else if (element.fieldType === "SUM_TOTAL") {
        let text = "" + props.group[element.field];
        let obj = { ...firstGroupRow };
        if (element.text != null) {
          let val = props.group[element.field];
          if (element.formatter != null) {
            val = formatters[element.formatter](val);
          }
          obj[element.field] = val;
          text = parseString(element.text, obj, element.formatterFieldMap);
        }
        props.group.item[element.field + index] =
          (
            <DataTableTd
              key={index}
              columnStructure={element}
              {...element}
              text={text}
              object={obj}
            />
          );
      }
    });
    return <DetailsRow
      {...props}
      item={props.group.item}
      itemIndex={props.group.itemIndex}
      className={groupRowClassName}
      onRenderCheck={(checkProps) => this.onRenderCheck(checkProps, props.group.itemIndex)}
      onClick={this.isInMultiselector ? () => { this.multiselectorOnClickRowHandler(props.group) } : null}
    />
  }

  createGroupsArray = (tableData) => {
    const groups = [];
    let currentGroup = null;
    let groupItemIndex = 0;
    if (tableData == null || tableData.length === 0) {
      return groups;
    }

    //Ottengo tutte le input di tipo "SUM_TOTAL", ovvero la somma del totale delle sottorighe
    let sumTotalInputs = [];
    if (this.props.groupedList) {
      this.props.groupColumns.forEach((column) => {
        if (column.fieldType === "SUM_TOTAL") {
          sumTotalInputs.push(column.field);
        }
      });
    }

    tableData.forEach((rowData, index) => {
      const currentLoopGroup = {};
      let key = "";
      this.props.groupByFields.forEach((groupByField) => {
        currentLoopGroup[groupByField] = rowData[groupByField];
        key += rowData[groupByField] + "-";
      });
      key = key + index;

      if (currentGroup === null) {
        currentGroup = currentLoopGroup;
        currentGroup.startIndex = index;
        currentGroup.key = key;
        currentGroup.name = key;
        currentGroup.count = 0;
        currentGroup.isCollapsed = true;
        currentGroup.itemIndex = groupItemIndex;
        currentGroup.groupRowsObject = [];
        groupItemIndex++;
      }

      let equalCheck = true;
      for (let i = 0; i < this.props.groupByFields.length; i++) {
        const groupByField = this.props.groupByFields[i];
        if (currentGroup[groupByField] !== currentLoopGroup[groupByField]) {
          equalCheck = false;
          break;
        }
      }
      if (!equalCheck) {
        currentGroup.item = this.createGroupItem(currentGroup.groupRowsObject[currentGroup.groupRowsObject.length - 1], index - 1, currentGroup.groupRowsObject);
        groups.push(currentGroup);
        currentGroup = currentLoopGroup;
        currentGroup.startIndex = index;
        currentGroup.key = key;
        currentGroup.name = key;
        currentGroup.count = 1;
        currentGroup.isCollapsed = true;
        currentGroup.itemIndex = groupItemIndex;
        currentGroup.groupRowsObject = [];
        groupItemIndex++;
      } else {
        currentGroup.count++;
      }
      currentGroup.groupRowsObject.push(rowData);

      //Aggiungo per ogni totalInput il valore corrispondente al field da sommare, cosi da creare la somma di tutte le sottorighe
      sumTotalInputs.forEach((totalInput) => {
        if (currentGroup[totalInput] == null) {
          currentGroup[totalInput] = 0;
        }
        currentGroup[totalInput] += (rowData[totalInput] != null ? rowData[totalInput] : 0);
      });

      if (currentGroup.item == null) {
        currentGroup.item = this.createGroupItem(rowData, index, currentGroup.groupRowsObject);
      }
    });

    groups.push(currentGroup);

    return groups;
  }

  /****** HANDLERS ******/
  // Handler for column click event
  onColumnSortHandler = (orderBy, order) => {
    if (this.props.onColumnSortChangeHandler) {
      this.props.onColumnSortChangeHandler(orderBy, order);
    } else {
      this.setState({ order: order, orderBy: orderBy, dataLoaded: false });
    }
  }
  // Handler for sticky rendering of the table header
  onRenderHeaderHandler = (props, defaultRender) => {
    if (this.props.showHeader == null || this.props.showHeader) {
      return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
          {
            (this.props.pStructure.showLiveSearch === true && this.state.groups.length > (this.props.pStructure.showLiveSearchLimit ? this.props.pStructure.showLiveSearchLimit : 15))
              ? (
                <div className="live-search-handle">
                  <TextField
                    autocomplete={"off"}
                    value={this.state.liveFilterString}
                    onChange={this.onLiveFilterChange}
                    iconProps={{
                      iconName: "ChromeClose",
                      style: { pointerEvents: "auto", cursor: "pointer", fontSize: "10px" },
                      onClick: () => this.onLiveFilterChange(null, '')
                    }}
                  />
                </div>
              )
              : null
          }
          {defaultRender({
            ...props
          })}
        </Sticky>
      );
    } else return null;
  }
  /************** FINE HANDLERS ***************** */

  onActiveItemChangedHandler = (event, item) => {
    const keyField = this.props.keyField != null
      ? this.props.keyField
      : this.props.pStructure.keyField;
    const row = this.state.tableData[item];

    this.setState({ selectedItem: row[keyField] });
  }

  onRenderRowHandler = (props) => {
    if (this.props.groupedList) {
      let itemsArray = [];
      for (let key in props.item) {
        const cellClassName = props.item[key].props.cellClassName != null ? parseString(props.item[key].props.cellClassName, props.item[key].props.object) : "";
        itemsArray.push(
          <div key={key} role="gridcell" className={"ms-DetailsRow-cell " + cellClassName}
            style={{ width: props.item[key].props.width }}>
            {props.item[key]}
          </div>
        );
      }
      const detailsRowClasses = ["ms-DetailsRow"];
      if (this.state.groups != null && this.props.style != null) {
        let currentGroupIndex = null;
        for (let i = 0; i < this.state.groups.length; i++) {
          const element = this.state.groups[i];
          let sum = element.startIndex + element.count;
          if ((props.itemIndex + 1) <= sum) {
            currentGroupIndex = i;
            break;
          }
        }
        const rowIndexModule = currentGroupIndex % 2;
        const rowClassName = this.props.rowClassName != null ? parseString(this.props.rowClassName, props.item[Object.keys(props.item)[0]].props.object) : "";
        detailsRowClasses.push(rowClassName);
        const currentDetailRowClassName = this.props.style.detailRowClassName + rowIndexModule;
        detailsRowClasses.push(currentDetailRowClassName);
      }
      return (
        <div className={detailsRowClasses.join(" ")} style={{ backgroundColor: "white" }}>
          <div className="ms-DetailsRow-fields" style={{ paddingLeft: this.props.childrenPaddingLeft != null ? this.props.childrenPaddingLeft : "96px", display: "flex", alignItems: "stretch" }} role="presentation">
            {itemsArray}
          </div>
        </div >
      );
    }
    const detailRowClasses = [];
    if (this.props.style != null && this.props.style.detailRowClassName != null) {
      const rowIndexModule = props.itemIndex % 2;
      const rowClassName = this.props.rowClassName != null ? parseString(this.props.rowClassName, props.item[Object.keys(props.item)[0]].props.object) : "";
      detailRowClasses.push(rowClassName);
      const currentDetailRowClassName = this.props.style.detailRowClassName + rowIndexModule;
      detailRowClasses.push(currentDetailRowClassName);
    }

    return (
      <DetailsRow
        {...props}
        onRenderCheck={(checkProps) => { return this.onRenderCheck(checkProps, props.itemIndex) }}
        className={detailRowClasses.join(" ")}
        styles={{ cell: { padding: 0 } }}
        onClick={this.isInMultiselector ? () => { this.multiselectorOnClickRowHandler(props) } : null}
      />
    )
  }

  onRenderCheck = (props, itemIndex) => {
    if (this.isInMultiselector) {
      props.className = "CheckedButtonHidden";
    } else {
      props.onClick = () => this._selection.setIndexSelected(itemIndex, !props.selected, false);
    }
    return <DetailsRowCheck {...props} />;
  }

  selectionModeResult = (object, index) => {
    /*SE LA DATATABLE HA LA SELEZIONE SINGOLA è NECESARIO TENERE SOLO L'ULTIMO VALORE SELEZIONATO*/
    const selectedItems = [];
    if (this.selectionMode === SelectionMode.single) {
      if (this.state.selectedItems.length > 0) {
        for (let idx in this.state.selectedItems) {
          const currentObject = this.state.selectedItems[idx];
          let currentNewObject = {};
          if (Object.keys(object) !== Object.keys(currentObject)) {
            //se non hanno le stesse chiavi ricostruisco l'oggetto con i campi comuni così da poterli conforntare
            for (let key in object) {
              lodash.set(currentNewObject, key, currentObject[key]);
            }
          } else {
            currentNewObject = this.state.selectedItems[idx];
          }

          if (!lodash.isEqual(object, currentNewObject)) {
            selectedItems.push(object);
          } else {
            this._selection.setIndexSelected(index, false);
          }
        }
      } else {
        selectedItems.push(object);
      }
    } else {
      selectedItems.push(object);
    }
    return selectedItems;
  }

  setDefaultSelection = () => {
    if (this.props.pStructure.defaultSelectionIndex != null) {
      const maxIndex = this._selection.getItems().length - 1;
      if (Array.isArray(this.props.pStructure.defaultSelectionIndex)) {
        this.props.pStructure.defaultSelectionIndex.forEach((index) => {
          if (maxIndex > index) {
            this._selection.setIndexSelected(index, true);
          }
        })
      } else {
        if (maxIndex > this.props.pStructure.defaultSelectionIndex) {
          this._selection.setIndexSelected(this.props.pStructure.defaultSelectionIndex, true);
        }
      }
    } else if (this.props.computeDefaultSelection) {
      const defaultSelectionIndex = this.props.computeDefaultSelection(this.state.tableData);
      if (defaultSelectionIndex != null && defaultSelectionIndex >= 0) {
        this._selection.setIndexSelected(defaultSelectionIndex, true);
      }
    }
  }

  onItemsSelectionChangedHandler = () => {
    let selectedItems = [];
    let selectedItemsPreference = [];
    this._selection.getSelectedIndices().forEach((index) => {
      const item = this._selection.getItems()[index];
      let object = null;
      if (this.props.groupedList) {
        if (item.item != null) {
          object = { ...item.item[Object.keys(item.item)[0]].props.object };
        } else {
          object = { ...item[Object.keys(item)[0]].props.object };
        }
      } else {
        object = { ...item[Object.keys(item)[0]].props.object };
      }

      if (this.preference != null) {
        if (Array.isArray(this.preference.itemsField)) {
          const selectedItem = {};
          this.preference.itemsField.forEach(field => {
            lodash.set(selectedItem, field, lodash.get(object, field));
          });
          selectedItemsPreference = selectedItemsPreference.concat(this.selectionModeResult(selectedItem));
        } else {
          if (lodash.has(object, this.preference.itemsField)) {
            const lodashObject = lodash.get(object, this.preference.itemsField)
            selectedItemsPreference = selectedItemsPreference.concat(this.selectionModeResult(lodashObject));
          }
        }
      }
      selectedItems = selectedItems.concat(this.selectionModeResult(object, index));
    });

    if (this.preference != null) {
      if (!this._selectionChangeDisabled) {
        this.discardedSelectedItemsPreference.forEach(discardedItem => selectedItemsPreference.push(discardedItem));
        this.selectedItemsPreference = selectedItemsPreference;
        this.props.onPersistUserPreferenceHandler(selectedItemsPreference, selectedItems);
      }
    }
    if (this.props.addMultiSelectorItem) {
      this.props.addMultiSelectorItem(this.props.multiSelectorSideKey, this._selection.getSelectedIndices())
    }
    this.setState({ selectedItems: selectedItems });
  }

  multiselectorOnClickRowHandler = (props) => {
    this._selection.setIndexSelected(props.itemIndex, !this._selection.isKeySelected(props.itemIndex), false);
  }

  computeAndSubmitFilterObject = () => {
    let hasSubmitted = false;
    if (this.props.filterObject != null) {
      const arrayKey = Object.keys(this.props.filterObject);
      const lastKey = arrayKey[arrayKey.length - 1];
      for (let key in this.props.filterObject) {
        const element = this.props.filterObject[key];

        let submit = false;
        if (lastKey === key && this.props.data == null) {
          submit = true;
          hasSubmitted = true;
        }

        this.onColumnFilterChangedHandler(element.value, key, element.filterType, submit);
      }
    }

    return hasSubmitted;
  }

  liveFilterCheckColumn = (col, groupRowObject, filter) => {
    if (col.field == null || col.field === "SPACER" || col.fieldType === "hidden") {
      return false;
    }
    let val = groupRowObject[col.field];
    if (col.text != null) {
      val = parseString(col.text, groupRowObject);
    }
    if (col.formatter != null) {
      val = formatters[col.formatter](val);
    }
    val = ("" + val).toLowerCase();
    if (val.indexOf(filter) !== -1) {
      return true;
    }
    return false;
  }


  liveFilterItems = (items) => {
    if (this.state.liveFilterStringConsolidated != null && this.state.liveFilterStringConsolidated.length > 0) {
      const filter = ("" + this.state.liveFilterStringConsolidated).toLowerCase();
      return items.filter((item) => {
        let found = false;
        for (const groupRowObject of item.groupRowsObject) {
          // singole oggetto della riga
          for (const col of this.props.pStructure.columns) {
            if (this.liveFilterCheckColumn(col, groupRowObject, filter) === true) {
              found = true;
              break;
            }
          }
          if (found === false) {
            if (this.props.pStructure.groupColumns != null && Array.isArray(this.props.pStructure.groupColumns) === true) {
              for (const col of this.props.pStructure.groupColumns) {
                if (this.liveFilterCheckColumn(col, groupRowObject, filter) === true) {
                  found = true;
                  break;
                }
              }
            }
          }
          if (found === true) {
            break;
          }
        }
        return found;
      });
    } else {
      return items;
    }
  }

  render() {

    if (!this.evaluetedShowIf) {
      return null;
    }

    const items = this.state.items;
    let tableFooter = null;
    if (this.props.pagination && (this.props.pagination.showPaginator === true || this.props.pagination.showPaginator === undefined)) {
      tableFooter = (
        <DataTablePagination
          loadTime={this.state.loadTime}
          rowsPerPageOptions={this.props.pagination.rowsPerPage}
          colSpan={this.props.columns.length}
          count={this.state.page.totalElements == null ? this.state.tableData.length : this.state.page.totalElements}
          rowsPerPage={this.state.page.size}
          totalPages={this.state.page.totalPages}
          page={this.props.viewState && this.props.viewState.page ? this.props.viewState.page + 1 : 1}
          onChangePage={this.props.pageChangedHandler}
          showLastRefresh={this.props.showLastRefresh == null ? true : this.props.showLastRefresh}
          showTotalItemsCount={this.props.showTotalItemsCount == null ? true : this.props.showTotalItemsCount}
          lastRefreshDate={this.state.lastRefreshDate}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          dataLoaded={this.state.dataLoaded}
          selectedItems={this.state.selectedItems}
          pagerAddons={this.props.pagerAddons}
        />
      );
    }

    const filtersOnTitle = [];
    if (this.props.filtersOnTitle != null) {
      this.props.filtersOnTitle.forEach((item, index) => {
        let value = null;

        if (this.state.filterObject != null && this.state.filterObject[item.field] != null && this.state.filterObject[item.field].value != null) {
          value = this.state.filterObject[item.field].value;
        }

        if (value == null) {
          value = item.defaultValue;
        }

        let fieldType = this.state.filterObject[item.field] ? this.state.filterObject[item.field].type : null;
        const onText = getLocalizedProperty(item, 'onText')
        const offText = getLocalizedProperty(item, 'offText')
        let filter = null;
        if (item.type === "Toggle") {
          filter = (
            <TooltipHost key={index} content={value ? onText : offText}>
              <GenericInput
                avoidContainer={true}
                type="Toggle"
                viewId={this.props.viewId}
                objectId={this.props.objectId}
                // eslint-disable-next-line
                value={(value == item.onValue)}
                onChange={(event, fieldName, checked) => { this.onColumnFilterChangedHandler(checked ? item.onValue : item.offValue, item.field, fieldType, true) }}
                onText=""
                offText=""
              >
              </GenericInput>
            </TooltipHost>
          );
        }
        if (filter != null) {
          filtersOnTitle.push(filter);
        }
      });
    }

    let modalProps = {};
    if (this.state.sendDataConfirmModal != null) {
      //this.state.sendDataConfirmModal.currentAction.confirmMessage
      modalProps.confirmMessage = intl.getHTML(this.state.sendDataConfirmModal.currentAction.confirmMessage, this.state.sendDataConfirmModal.object).d(this.state.sendDataConfirmModal.currentAction.confirmMessage);
      modalProps.confirmFunction = () => {
        if (this.state.sendDataConfirmModal.currentAction) {
          this.sendData(this.state.sendDataConfirmModal.currentAction, this.state.sendDataConfirmModal.object);
        }
        if (this.state.sendDataConfirmModal.callback) {
          this.state.sendDataConfirmModal.callback();
        }
      }
    } else if (this.state.sendDataPreActionDialog) {
      modalProps.confirmMessage = this.state.confirmMessage;
      modalProps.confirmFunction = () => {
        this.performSendData(this.state.sendDataPreActionDialog.currentAction, this.state.sendDataPreActionDialog.object);
      }
    } else if (this.state.wagxEngineParams) {
      modalProps.confirmMessage = this.state.confirmMessage;
      modalProps.confirmFunction = this.state.onConfirmCallback;
    }

    let sendDataConfirmDialog = modalProps.hasOwnProperty("confirmFunction") && (this.state.showConfirmModal || this.state.showConfirmModalRequestInProgress)
      ? (
        <ConfirmModal
          confirmTitle={modalProps.confirmTitle}
          confirmMessage={modalProps.confirmMessage}
          onConfirm={modalProps.confirmFunction}
          onCancel={
            () => this.setState({
              sendDataConfirmModal: null,
              sendDataPreActionDialog: null,
              confirmMessage: null,
              showConfirmModal: false,
              wagxEngineModel: null
            })
          }
          requestInProgress={this.state.showConfirmModalRequestInProgress}
        />
      )
      : null;

    const groups = this.liveFilterItems(this.state.groups);
    const deleteDialog = this.state.showDeleteDialog ? this.createDeleteDialog() : null;
    let title = undefined;
    let replacementMap = {
      totalElements: this.state.dataLoaded ? (this.state.page.totalElements == null ? this.state.tableData.length : this.state.page.totalElements) : 0,
      totalNoFilterElements: this.state.dataLoaded ? (this.state.page.totalNoFilterElements == null ? this.state.tableData.length : this.state.page.totalNoFilterElements) : 0,
      size: this.state.dataLoaded ? this.state.page.size : 0
    };

    if (this.props.showTitle !== false) {
      if (this.state.parsedTitle) {
        title = this.state.parsedTitle;

      } else if (this.props.advTitle && this.state.parsedTitle) {
        title = parseAdvancedTitle(this.state.parsedTitle, { ...(this.state.tableData[0]), ...replacementMap });
      } else if (this.props.advTitle) {
        title = parseAdvancedTitle(this.props.advTitle, { ...(this.state.tableData[0] ? this.state.tableData[0] : []), ...replacementMap });
      } else {
        const localizedTitle = getLocalizedProperty(this.props, "title", this.props.title, replacementMap);
        const localizedDestinationTitle = this.props.viewState != null
          ? getLocalizedProperty(this.props.viewState, "destinationTitle", this.props.viewState.destinationTitle, replacementMap)
          : "";
        title = localizedTitle + localizedDestinationTitle;
      }
    }
    let actions = getDefaultActions(this.state.selectedItem, (this.props.viewState && this.props.viewState.fromLink), this).concat(getCommandBarActions(this.props.actions, this, this.props.loopedPageObject, replacementMap));
    let genericMessageBar = null;
    if (this.state.exportInProgress) {
      actions.forEach((action) => {
        if (action.key === actionTypes.EXPORT || action.key.toString().startsWith(actionTypes.CUSTOM_DATA_SEND)) {
          action.disabled = true;
        }
      });
      genericMessageBar = (
        <Layer>
          <MessageBar
            dismissButtonAriaLabel="Close"
            messageBarType={MessageBarType.info}
            isMultiline={false} >
            {intl.getHTML("DataTable.exportingMessageBar").d("L'esportazione selezionata è in elaborazione, a breve sarà scaricato il file...")}
          </MessageBar>
        </Layer>
      );
    }
    if (this.state.downloadInProgress) {
      genericMessageBar = (
        <Layer>
          <MessageBar
            dismissButtonAriaLabel="Close"
            messageBarType={MessageBarType.info}
            isMultiline={false} >
            {intl.getHTML("DataTable.downloadingMessageBar").d("Il download selezionato è in elaborazione, a breve sarà scaricato il file...")}
          </MessageBar>
        </Layer>
      );
    }

    const loadingSpinner = !this.state.dataLoaded ? <Spinner /> : null;
    let showSpinner = !this.state.dataLoaded;

    let bodyClassName = "";
    if (this.props.style != null) {
      bodyClassName = this.props.style.bodyClassName;
    }

    let showFilters = this.props.filterComponents && this.props.filterComponents.length > 0;

    const dataTableClassName = ["DataTable"];
    if (this.props.pStructure.dataTableClassName != null) {
      dataTableClassName.push(this.props.pStructure.dataTableClassName);
    }

    let dataTableTitleBarClasses = "DataTableTitle";
    if (this.props.titleBarClassName != null) {
      dataTableTitleBarClasses = this.props.titleBarClassName;
    }

    let indentWidth = {};
    if (this.props.indentWidth != null) {
      indentWidth = { indentWidth: this.props.indentWidth };
    }
    const showCommandBar = this.props.showAction == null || this.props.showAction;
    const showTitleBar = title !== undefined && title !== "";
    const hasData = !showSpinner && (this.state.page.totalElements == null ? this.state.tableData.length : this.state.page.totalElements) > 0;
    const optionalProps = {}
    if (this.props.shouldVirtualize != null) {
      optionalProps.onShouldVirtualize = () => this.props.shouldVirtualize
    }



    const dataTableContentData = (
      <React.Fragment>
        <DetailsList
          groups={groups}
          disableSelectionZone={true}
          items={items}
          compact={true}
          selection={this.props.selectionEnabled ? this._selection : null}
          columns={this.state.columns}
          selectionMode={this.selectionMode}
          checkboxVisibility={this.props.selectionEnabled ? CheckboxVisibility.onHover : CheckboxVisibility.hidden}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={this.props.showHeaders === null || this.props.showHeaders}
          onRenderDetailsHeader={this.onRenderHeaderHandler}
          onColumnClick={this.onColumnClickHandler}
          selectionPreservedOnEmptyClick={true}
          onActiveItemChanged={this.onActiveItemChangedHandler}
          {...indentWidth}
          {...optionalProps}
          groupProps={{
            isAllGroupsCollapsed: true,
            onRenderHeader: this.onRenderGroupHeader,
            collapseAllVisibility: this.props.showCollapseAll == null || this.props.showCollapseAll ? CollapseAllVisibility.visible : CollapseAllVisibility.hidden
          }}
          onRenderRow={this.onRenderRowHandler}
        />
        {
          this.state.contextualMenuProps &&
          <div onBlur={this.handleOnDateBlur}>
            <ContextualMenu {...this.state.contextualMenuProps} onRenderMenuList={(menuListProps, defaultRender) => this.renderMenuList(menuListProps, defaultRender)} />
          </div>
        }
      </React.Fragment>
    );

    let dataTableContentDataContainer;
    let height;
    if (this.props.isScrollable === false) {
      dataTableContentDataContainer = (
        <div className="datatable-container-no-scrollable-pane">
          {dataTableContentData}
        </div>
      );
      if (this.state.loading) {
        height = "1%";
      } else {
        height = hasData ? "100%" : undefined;
      }
    } else {
      dataTableContentDataContainer = (
        <ScrollablePane>
          {dataTableContentData}
        </ScrollablePane>
      );
      height = "100%";
    }

    const footerActionList = Array.isArray(this.props.footerActionList) === true
      ? getCommandBarActions(this.props.footerActionList, this, this.props.loopedPageObject, replacementMap)
      : null;
    const titleResult = <div className="DataTableTitleContainer">
      {this.props.showLeftAngleLabel === true ? <div className={"DataTableLeftAngleLabel"}></div> : null}
      {title ? <span className="title-string">{title}</span> : null}
      {this.props.showRightAngleLabel === true ? <div className={"DataTableRightAngleLabel"}></div> : null}
    </div>;
    const table = <div className={dataTableClassName.join(" ")}>
      {sendDataConfirmDialog}
      {deleteDialog}
      <div className="DataTableTop">
        {showCommandBar ?
          <div className="DataTableCommandBar">
            <CommandBar items={actions} ></CommandBar>
          </div> : null
        }
        {genericMessageBar}
        {showTitleBar ?
          <div className={dataTableTitleBarClasses}>
            {titleResult}
            {this.props.showAction === false ?
              <div className={"DataTableActionContainer"}>
                {actions.map((action, index) => {
                  return <span key={index} className="top-right-button">
                    <IconButton
                      className={["DataTableTopRightButton", action.className].join(' ')}
                      iconProps={{ iconName: action.iconProps != null ? action.iconProps.iconName : null, color: "white" }}
                      onClick={(event) => action.onClick != null ? action.onClick(event) : null}
                      title={action.name}
                      ariaLabel={action.name}
                      menuProps={action.subMenuProps}
                      disabled={action.disabled ? action.disabled : false} />
                  </span>
                })}</div> : null}
            {filtersOnTitle.length > 0 ?
              <div className="filters-on-title-container">
                {filtersOnTitle}
              </div> : null}
          </div> : null
        }
        {showFilters ?
          <div className="DataTableHeader">
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
              <DataTableToolbar
                className={this.props.filterBarClassName}
                actions={this.props.actions}
                onFilterList={this.props.filterListHandler}
                filterComponents={this.props.filterComponents}
                filters={this.props.viewState ? this.props.viewState.filters : []}
                applyFilters={this.props.viewState ? this.props.viewState.applyFilters : []}
                searchMode={this.searchMode}
                afterOnChange={this.props.afterOnChange}
                object={this.props.loadedObject}
                profiles={this.props.auth.appState.profiles}
              />
            </Sticky>
          </div> : null
        }
      </div>
      <div className="DataTableBodyContainer">
        <div className={"DataTableBody " + (bodyClassName ? bodyClassName : "")} style={{ height: height, minHeight: height, maxHeight: height }}>
          <div className="DataTableBodyContent" style={{
            height: height,
            position: 'relative',
            maxHeight: 'inherit'
          }}>
            {!showSpinner ?
              dataTableContentDataContainer
              : loadingSpinner}
          </div>
        </div>
      </div>
      {tableFooter}
      {
        footerActionList != null
          ? (
            <div className="data-table-footer-action-container">
              {
                footerActionList.map((footerAction) => {
                  return (
                    <DefaultButton
                      key={footerAction.key}
                      className={footerAction.className}
                      onClick={footerAction.onClick}
                      text={footerAction.name}
                      disabled={footerAction.disabled === true}
                    />
                  );
                })
              }
            </div>
          )
          : null
      }
    </div>;

    return (
      this.props.expandible ? <WagxExpandPanel {...this.props} {...this.props.expandibleProps} items={[...this.state.items]} elements={this.state.items}> {table}</WagxExpandPanel> : table
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    dataTable: state.dataTable,
    currentLocale: state.auth.currentLocale,
    homeOperativaState: state.homeOperativa
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCustomComponentState: (state) => dispatch(actions.setCustomComponentState({ state: state })),
    setPageState: (state) => dispatch(actions.setPageState({ state: state })),
    setDataTableState: (state) => dispatch(actions.setDataTableState({ state: state })),
    hideSearchModal: () => dispatch(actions.hideSearchModal()),
    showLongOperationWaitingModal: (percent, percentTitle, percentDescription) => dispatch(actions.showLongOperationWaitingModal({ percent: percent, percentTitle: percentTitle, percentDescription: percentDescription })),
    hideLongOperationWaitingModal: () => dispatch(actions.hideLongOperationWaitingModal()),
    showSearchModal: (search, mainStyle, objectId, loadedObject) => dispatch(actions.showSearchModal({ search: search, mainStyle: mainStyle, objectId: objectId, loadedObject: loadedObject })),
    showIframeModal: (url, title, width, height, updatePageOnClose) => dispatch(actions.showIframeModal({ url: url, title: title, width: width, height: height, updatePageOnClose: updatePageOnClose }))
  }
}

DataTable.contextType = ViewContext;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataTable));
