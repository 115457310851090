import intl from 'react-intl-universal';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const PARAMETER_PARSE_FUNCTION_MAP = {
    CURRENT_LOCALE: () => { return intl.getInitOptions().currentLocale }
}

export const parseParameter = (stringToParse) => {
    if (stringToParse == null || stringToParse === "" || typeof stringToParse !== "string") {
        return stringToParse;
    }

    let parsedString = stringToParse;

    for (const key in PARAMETER_PARSE_FUNCTION_MAP) {
        const parameterToReplace = "#" + key + "#";
        parsedString = parsedString.replaceAll(parameterToReplace, PARAMETER_PARSE_FUNCTION_MAP[key]());
    }

    return parsedString;
}
