import { updateObject } from '../utils'
import * as actionTypes from '../actions/action-types';

const initialState = {

};

const setCustomComponentState = (state, action) => {
  const updatedState = { ...state,...action.state };
  return updateObject(state, updatedState);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOM_COMPONENT_STATE: return setCustomComponentState(state, action);
    default:
      return state;
  }
};

export default reducer;
