export const DETAIL = "detail";
export const LINK = "link";
export const HTML = "html";
export const ICON_BUTTON = "iconButton";
export const ICON = "icon";
export const ITEMS_COUNT = "itemsCount";
export const FILE_DOWNLOAD = "fileDownload";
export const OPEN_VIEW = "openView";
export const SEND_DATA = "sendData";
export const CONDITIONAL = "conditional";
export const CHOOSE = "choose";
export const ICON_SWITCH = "iconSwitch";
export const TEXT_BORDER = "textWithBorder";
export const GENERIC_INPUT = "genericInput";
export const ENGINE_WORKFLOW = "engineWorkflow";
export const CUSTOM = "custom";